import React, { useEffect, useState } from "react"
import styled from "styled-components"
import axios from "axios"
import { Link, graphql } from "gatsby"



import InvestorsNavbar from "../../components/investors-navbar"
import fileIcon from "../../assets/images/pdf-icon.svg"
import playIcon from "../../assets/images/play-icon.svg"
import Video from "../../components/video"
import ClipLoader from "react-spinners/ClipLoader"

import {
  H2,
  Sectionheader,
  PageContainer,
} from "../../components/common"
import SEO from "../../components/seo"

const TableContainer = styled.div`
  width: 810px;
  margin-top: 80px;

  &:last-child {
    margin-bottom: 80px;
  }

  @media screen and (max-width: 980px) {
    width: 624px;
  }

  @media screen and (max-width: 640px) {
    width: 333px;
  }
`

const Table = styled.table`
  border-spacing: 0px;
  border-collapse: collapse;
  width: 100%;

  td {
    border: 1px solid #afafaf;
    border-left: none;
    border-right: none;

    p {
      width: 100%;
      display: flex;
    }

    span {
      width: 250px;
    }
  }
`

const FileButton = styled.button`
  width: 250px;
  height: 100%;
  box-shadow: none;
  border: none;
  background: url(${fileIcon}) no-repeat center;
  outline: none;
  cursor: pointer;

  @media screen and (max-width: 640px) {
    width: 50px;
  }
`

const VideoButton = styled(FileButton)`
  background: url(${playIcon}) no-repeat center;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  ${H2} {
    margin: 0;
    width: 100%;
  }

  span {
    width: 250px;
    text-align: center;
  }

  @media screen and (max-width: 640px) {
    span {
      display: none;
    }
  }
`

const ElementsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 81px;
  padding-left: 20px;

  @media screen and (max-width: 640px) {
    span {
      display: none;
    }
  }
`

const Loader = styled.div`
  margin-top: 20px;
`

const Navbar = styled.div`
  display: flex;
  width: 809px;
  border-top: ${props => (props.border ? '1px solid #979797' : "none")};
  justify-content: center;
  padding: 21px 64px 0;
  box-sizing: border-box;
  margin-top: -50px;

  @media screen and (max-width: 980px) {
    display: ${props => (props.showOnMobile ? "flex" : "none")};
    width: 80%;
  }

  @media screen and (max-width: 640px) {
    padding: 21px 0px 0;
    width: 100%;
    overflow-x: scroll;
    padding-left: 65px;
  }
`

const NavLink = styled(Link)`
  padding: 0 10px;
  display: flex;
  position: relative;
  height: 40px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px dashed transparent;
  font-size: 13px;
  transition: 0.4s border;
  margin-left: 5px;
  font-weight: 700;

  span {
    height: 13px;
  }

  &.active,
  &:hover {
    border: 1px dashed #000;
  }

  &.unhoverable {
    border: none;
  }

  &.hide {
    display: none;
  }

  @media screen and (max-width: 640px) {
    text-align: center;
  }
`
export default function ReportsPage({
  data: {
    strapiData: { title, reportgroups },
  },
}) {
  const [reportgroupsApi, setReportgroupsApi] = useState(reportgroups)
  const [showVideo, setShowVideo] = useState(false)
  const [id, setId] = useState(649525389)
  const [loading, setLoading] = useState(true)
  const [yearIndex, setYearIndex] = useState(0)
  const [years, setYears] = useState([])

  useEffect(() => {

    axios.get('https://strapi.regily.io/reportspage')
      .then(response => {
        const apiReportsGroup = response.data.reportgroups
        if ((reportgroups.length !== apiReportsGroup.length) ||
          (reportgroups[0].reports.length !== apiReportsGroup[0].reports.length)) {
          setReportgroupsApi(apiReportsGroup)
        } else {
          setReportgroupsApi(response.data.reportgroups)
        }
        setLoading(false)
        setYears(response.data.reportgroups.map(({ year }) => { return year }))
      })

    console.log(years)
  }, [])

  const extractVideoId = (link) => {
    const url = new URL(link)
    if (url.searchParams.has('v')) {
      setId(url.searchParams.get('v'))
    } else {
      setId(link.split('/').pop())
    }
    setShowVideo(true)
  }
  return (
    <>
      <SEO
        title="Checkin.com Group AB - Presentations & Reports"
        description="Latest reports, webcasts and presentations for investors from Checkin.com Group."
        canonical="/investors/reports"
        ogImage="/images/og.jpg"
      />
      <PageContainer>
        <InvestorsNavbar />
        <Sectionheader>
          <H2>Reports and Webcasts</H2>
        </Sectionheader>
        <Loader><ClipLoader loading={loading} /> </Loader>

        <Navbar showOnMobile={true}>
          {years.map((year, index) => (
            <NavLink key={index} onClick={() => setYearIndex(index)} to={`/investors/reports#${year}`} activeClassName="active">
              {year}
            </NavLink>
          ))}
        </Navbar>
        <TableContainer>
          <HeaderContainer>
            <H2>{reportgroupsApi[yearIndex].year}</H2>
            <span>Report</span>
            <span>Webcast</span>
          </HeaderContainer>
          <Table>
            <tbody>
              {reportgroupsApi[yearIndex].reports.map(({ name, presentation, report }) => (
                <tr key={name}>
                  <td>
                    <ElementsContainer>
                      <p>{name}</p>
                      {!!report ? (
                        <FileButton
                          onClick={() => window.open(report, "_blank")}
                        />
                      ) : (
                        <span />
                      )}
                      {!!presentation ? (
                        <VideoButton
                          onClick={() => extractVideoId(presentation)}
                        />
                      ) : (
                        <span />
                      )}
                    </ElementsContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </PageContainer>
      {showVideo && (
        <Video id={id} type="youtube" onClose={() => setShowVideo(false)} />
      )}
    </>
  )
}

export const query = graphql`
  query {
    strapiData: strapiReportspage {
      title
      reportgroups {
        year
        reports {
          name
          presentation
          report
        }
      }
    }
  }
`
