import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"


import InvestorsNavbar from "../../components/investors-navbar"
import SEO from "../../components/seo"
import AddToCalendar from 'react-add-to-calendar';
import 'react-add-to-calendar/dist/react-add-to-calendar.css';

import {
  H2,
  Sectionheader,
  SectionDescription,
  PageContainer,
} from "../../components/common"

const Table = styled.table`
  border-spacing: 0px;
  border-collapse: collapse;
  width: 100%;

  .react-add-to-calendar{
    a { border: none !important;}
    span {
      display: contents !important;
    }
  }

  .calendarDropdown {
    width: 150px;
    position: absolute;
    top: 30px;
    left: 1px;
    padding: 5px 0 5px 8px;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
    border: 1px solid #a8a8a8;
    background-color: #fff;
    text-align: left;
    z-index: 9999;
    i { padding-right: 10px; }
    ul {
      list-style: none;
      margin: 0;

      li {
        padding: 5px 0px;
      }

      a {
        color: #000;
        text-decoration: none;
      }
    }
  }

  #atcb-reference {
    display: none;
  }

  td {
    padding: 20px;
    border: 1px solid #afafaf;
    border-left: none;
    border-right: none;


    p {
      font-size: 20px;
    }

    span {
      font-size: 20px;
      font-weight: 700;
      display: block;
    }
    span {
      font-size: 20px;
      font-weight: 700;
      display: block;
    }
  }

  @media screen and (max-width: 980px) {
    .button{
      padding: 0px;
      width: 160px;
    }
  }

  @media screen and (max-width: 640px) {
    tr {
      td:first-child {
        display: none;
      }

    }

    p {
      font-size: 16px !important;
      margin-bottom: 8px;
    }
  }
`

const CalendarContainer = styled.div`
  width: 810px;
  margin-top: 80px;

  &:last-child {
    margin-bottom: 80px;
  }

  @media screen and (max-width: 980px) {
    width: 624px;
  }

  @media screen and (max-width: 640px) {
    width: 333px;
  }
`

const MobileDate = styled.span`
  display: none !important;
  font-size: 16px !important;
  font-weight: 700;

  @media screen and (max-width: 640px) {
    display: block !important;
  }
`

const Navbar = styled.div`
  display: flex;
  width: 809px;
  border-top: ${props => (props.border ? '1px solid #979797' : "none")};
  justify-content: center;
  padding: 21px 64px 0;
  box-sizing: border-box;

  @media screen and (max-width: 980px) {
    width: 80%;
  }

  @media screen and (max-width: 640px) {
    padding: 21px 0px 0;
    width: 100%;
  }
`

const NavLink = styled(Link)`
  padding: 0 10px;
  display: flex;
  position: relative;
  height: 40px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px dashed transparent;
  font-size: 13px;
  transition: 0.4s border;
  margin-left: 5px;
  font-weight: 700;

  &.active{
    border: 1px dashed #000;
  }

  span {
    height: 13px;
  }

  &.active,
  &:hover {
    border: 1px dashed #000;
  }

  &.unhoverable {
    border: none;
  }

  &.hide {
    display: none;
  }

  @media screen and (max-width: 640px) {
    text-align: center;
  }
`

export default function CalendarPage({
  data: {
    strapiData: { title, subtitle, eventgroups },
  },
}) {
  const [tab, setTab] = useState(0)
  const [events, setEvents] = useState([])
  const currentEvents = [], historicalEvents = []
  eventgroups.map((eventgroup) => {
    eventgroup.events.map((event) => {

      if (new Date(event.date.split(' ')[0]).setHours(23, 59) > new Date().getTime()) {
        currentEvents.push({
          title: event.title,
          date: event.date.split(' ')[0],
          fullDate: event.date,
        })
      } else {
        historicalEvents.push({
          title: event.title,
          date: event.date,
          fullDate: event.date,

        })
      }

    })
  })
  historicalEvents.reverse()


  useEffect(() => {
    tab === 0 ? setEvents(currentEvents) : setEvents(historicalEvents)
  }, [tab])

  return (
    <>
      <SEO
        title="Checkin.com Group AB - Calendar for upcoming events"
        description="Check the calendar to stay on top of Checkin.com Group's upcoming events and important dates"
        canonical="/investors/calendar"
        ogImage="/images/og.jpg"
      />
      <PageContainer>
        <InvestorsNavbar />
        <Sectionheader>
          <H2>{title}</H2>
          <SectionDescription>{subtitle}</SectionDescription>
        </Sectionheader>

        <Navbar >
          <NavLink onClick={() => setTab(0)} to={`/investors/calendar#Upcoming`} className={tab === 0 && "active"}>
            Current
          </NavLink>
          <NavLink onClick={() => setTab(1)} to={`/investors/calendar#Historical`} className={tab === 1 && "active"}>
            Historical
          </NavLink>
        </Navbar>

        <CalendarContainer >
          <H2>{tab === 1 ? "Historical" : "Current"}</H2>
          <Table>
            <tbody>
              {events.map(({ title, date, fullDate }) => {
                return (<tr key={title}>

                  <td>

                    <span>{fullDate}</span>
                  </td>
                  <td>
                    <p>{title}</p>
                    <MobileDate>{fullDate}</MobileDate>

                  </td>
                  {tab === 0 ? (<td className="button">
                    <AddToCalendar
                      dropdownClass='calendarDropdown'
                      buttonLabel='Add to cal'
                      buttonTemplate={{ 'calendar-plus-o': 'left' }}
                      event={{
                        title: `Checkin.com  ${title}`,
                        startTime: fullDate,
                        endTime: fullDate
                      }} />
                  </td>) : null}

                </tr>)

              })}
            </tbody>
          </Table>
        </CalendarContainer>
      </PageContainer>
    </>
  )
}

export const query = graphql`
  query {
    strapiData: strapiCalendarpage {
      title
      subtitle
      eventgroups {
        year
        events {
          title
          date
        }
      }
    }
  }
`
