import styled from "styled-components"

const LayoutGrid = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: auto repeat(12, 60px) auto;
  grid-gap: 20px;
  grid-auto-rows: min-content;
  align-items: start;
  padding-top: 150px;
  min-height: 100%;

  @media screen and (max-width: 980px) {
    grid-template-columns: auto repeat(8, 60px) auto;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: auto 315px auto;
  }

  @media screen and (max-width: 375px) {
    grid-template-columns: 0 auto 0;
  }
`

export default LayoutGrid
