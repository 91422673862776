import React, { useEffect } from "react"
import styled from "styled-components"

import InvestorsNavbar from "../../components/investors-navbar"
import SEO from "../../components/seo"

import { H2, Sectionheader } from "../../components/common"

export const PageContainer = styled.div`
  /* mobile first */

  /* tablet */
  @media screen and (min-width: 640px) {
    margin: 0 auto;
    padding-top: 50px;
    max-width: 614px;
  }

  /* desktop */
  @media screen and (min-width: 980px) {
    padding-top: 135px;
    max-width: 1170px;
    margin: 0 auto;
  }
`

const SidePaddingWrapper = styled.div`
  @media screen and (max-width: 640px) {
    padding: 0 20px;
  }
`

const Table = styled.table`
  /* mobile first */

  thead {
    font-weight: bold;
  }

  th,
  td {
    border-bottom: 1px solid #afafaf;
    border-left: none;
    border-right: none;
    padding: 20px;
  }

  width: 100%;
  border-top: 1px solid #afafaf;
  text-align: left;
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 15px;

  /* tablet */
  @media screen and (min-width: 640px) {
    font-size: 20px;
  }
`

const TableWrapper = styled.div`
  overflow: hidden;
  overflow-x: scroll;
  white-space: nowrap;
`
const InformativeBlock = styled.div`
  align-self: start;
  font-size: 17px;
  margin-bottom: 20px;

  h3 {
    margin-bottom: 10px;
  }
`

const SubTitle = styled.h2`
  margin: 45px 0 35px 0;
`

const CisionIframe = styled.iframe`
  width: 100%;
  border: none;
  height: 500px;

  .tab-content {
    height: 500px;
  }
`

const ResizableIframe = props => {
  const iframeReference = React.createRef()

  useEffect(() => {
    console.log("height changed")
    // Create browser compatible event handler.
    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent"
    const eventer = window[eventMethod]
    const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message"
    // Listen for a message from the iframe.
    eventer(
      messageEvent,
      e => {
        if (e.data.iframeHeight && iframeReference && iframeReference.current) {
          iframeReference.current.style.minHeight = e.data.iframeHeight + "px"
          console.log(e.data)
        }
      },
      false
    )
  }, [iframeReference])

  return <CisionIframe ref={iframeReference} {...props} />
}

export default function SharePage() {
  return (
    <React.Fragment>
      <SEO
        title="Checkin.com Group AB - The share"
        description="General information and historical performance of the Checkin.com Group share, CHECK."
        canonical="/investors/share"
        ogImage="/images/og.jpg"
      />
      <PageContainer>
        <InvestorsNavbar />
        <Sectionheader>
          <H2>The Share</H2>
        </Sectionheader>
        <SidePaddingWrapper>
          <InformativeBlock>
            <h3>Shortname</h3>
            <p>CHECK</p>
          </InformativeBlock>
          <InformativeBlock>
            <h3>ISIN</h3>
            <p>SE0015810502</p>
          </InformativeBlock>
          <InformativeBlock>
            <h3>Certified adviser</h3>
            <p>Carnegie Investment Bank AB (publ)</p>
            <p>tel: +46 (0)73 856 42 65</p>
          </InformativeBlock>
          <SubTitle>Largest owners as of December 31, 2023</SubTitle>
        </SidePaddingWrapper>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <th>
                  <span>Owner</span>
                </th>
                <th>
                  <span>Shares</span>
                </th>
                <th>
                  <span>Ownership</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span>Kristoffer Cassel, privately and via companies</span>
                </td>
                <td>
                  <span>5 355 985</span>
                </td>
                <td>
                  <span>18.3%</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Quinary Investment AB</span>
                </td>
                <td>
                  <span>3 401 474</span>
                </td>
                <td>
                  <span>11.6%</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Alexey Kuznetsov, privately and via companies</span>
                </td>
                <td>
                  <span>3 155 616</span>
                </td>
                <td>
                  <span>10.8%</span>
                </td>
              </tr>

              <tr>
                <td>
                  <span>Nicklas Storåkers</span>
                </td>
                <td>
                  <span>2 384 680</span>
                </td>
                <td>
                  <span>8.1%</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Avanza Pension</span>
                </td>
                <td>
                  <span>1 826 966</span>
                </td>
                <td>
                  <span>6.2%</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>TIN Fonder</span>
                </td>
                <td>
                  <span>1 770 508</span>
                </td>
                <td>
                  <span>6.0%</span>
                </td>
              </tr>

              <tr>
                <td>
                  <span>Erik Selin, privately and via companies</span>
                </td>
                <td>
                  <span>1 768 000</span>
                </td>
                <td>
                  <span>6.0%</span>
                </td>
              </tr>

              <tr>
                <td>
                  <span>Wilhelm Risberg</span>
                </td>
                <td>
                  <span>736 622</span>
                </td>
                <td>
                  <span>2.5%</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Knutsson Holdings</span>
                </td>
                <td>
                  <span>701 807</span>
                </td>
                <td>
                  <span>2.4%</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Eirik Winter</span>
                </td>
                <td>
                  <span>672,656</span>
                </td>
                <td>
                  <span>2.3%</span>
                </td>
              </tr>
            </tbody>
          </Table>
        </TableWrapper>
        <SidePaddingWrapper>
          <SubTitle>Details</SubTitle>
          <ResizableIframe src="/cision-iframe/sharegraph.html" />
        </SidePaddingWrapper>
      </PageContainer>
    </React.Fragment>
  )
}
