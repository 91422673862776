/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "normalize.css"
import "nouislider"
import Cookies from "js-cookie"
import cuid from "cuid"
import getUrlParam from "./src/utils/get-url-param"
import smoothscroll from "smoothscroll-polyfill"

import "./src/utils/fonts.css"
import "./node_modules/nouislider/distribute/nouislider.min.css"
require("imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap")

smoothscroll.polyfill()

window.hsConversationsSettings = {
  loadImmediately: false,
  inlineEmbedSelector: "#hs-chat-widget-container",
}

  // window.pushToDataLayer = response => {
  //   var reveal = { reveal: response }
  //   window.dataLayer.push(reveal)
  //   window.dataLayer.push({ event: "Clearbit Loaded" })
  //   return true
  // }
  // ;((d, script) => {
  //   setTimeout(() => {
  //     script = d.createElement("script")
  //     script.type = "text/javascript"
  //     script.async = true
  //     script.onload = () => {}
  //     script.src =
  //       "//reveal.clearbit.com/v1/companies/reveal?authorization=pk_5adb5b2227763b935eef8dd79f4dd817&callback=pushToDataLayer"
  //     d.getElementsByTagName("head")[0].appendChild(script)
  //   }, 5000)
  // })(document)
  // ;((d, script) => {
  //   setTimeout(() => {
  //     if (window.HubSpotConversations) return
  //     script = d.createElement("script")
  //     script.type = "text/javascript"
  //     script.async = true
  //     script.onload = () => {}
  //     script.src = "//js.hs-scripts.com/5855020.js"
  //     d.getElementsByTagName("head")[0].appendChild(script)
  //   }, 5000)
  // })(document)
  ; ((d, script) => {
    const _linkedin_partner_id = "1642665"
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
    window._linkedin_data_partner_ids.push(_linkedin_partner_id)

    setTimeout(() => {
      script = d.createElement("script")
      script.type = "text/javascript"
      script.async = true
      script.onload = () => { }
      script.src = "//snap.licdn.com/li.lms-analytics/insight.min.js"
      d.getElementsByTagName("head")[0].appendChild(script)
    }, 5000)
  })(document)
  ; ((f, b, e, v, n, t, s) => {
    setTimeout(() => {
      if (f.fbq) return
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = "2.0"
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)

      window.fbq("init", "369305210379157")
      window.fbq("track", "PageView")
    }, 5000)
  })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js")

window.dataLayer = window.dataLayer || []

if (!Cookies.get("gtag-id"))
  Cookies.set("gtag-id", getUrlParam("cuid") || cuid())
window.dataLayer &&
  window.dataLayer.push({ event: "cuID", cuID: Cookies.get("gtag-id") })

window.dataLayer && window.dataLayer.push({ u_gclid: getUrlParam("gclid") })

!window.regilyEventCallback &&
  Object.defineProperty(window, "regilyEventCallback", {
    value: function (regilyEventCallbackData) {
      return Promise.resolve({})
    },
  })

!window.regilyUpdateCallback &&
  Object.defineProperty(window, "regilyUpdateCallback", {
    value: function (data, dataDiff) {
      return Promise.resolve({})
    },
  })

!window.regilyDoneCallback &&
  Object.defineProperty(window, "regilyDoneCallback", {
    value: function (regilyDoneCallbackData) {
      return Promise.resolve({})
    },
  })

window.addEventListener(
  "touchmove",
  e => {
    e = e.originalEvent || e
    if (e.scale > 1 || e.scale < 1) {
      e.preventDefault()
    }
  },
  { passive: false }
)

export { default as wrapPageElement } from "./src/state/wrapper"
