import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import {
  H1,
  H2,
  Sectionheader,
  SectionDescription,
  PageContainer,
} from "../components/common"
import SEO from "../components/seo"

const Table = styled.table`
  border-spacing: 0px;
  border-collapse: collapse;
  width: 100%;

  td {
    border: 1px solid #afafaf;
    border-left: none;
    border-right: none;

    &:first-child {
      width: 200px;
    }

    p {
      font-size: 20px;
    }

    span {
      font-size: 20px;
      font-weight: 700;
      display: block;
    }
  }
`

const CategoryContainer = styled.div`
  width: 810px;
  margin-top: 80px;

  ${H2} {
    font-size: 18px;
    text-transform: uppercase;
    color: #535353;
    font-weight: 700;
  }

  &:last-child {
    margin-bottom: 80px;
  }

  @media screen and (max-width: 980px) {
    width: 623px;
  }

  @media screen and (max-width: 640px) {
    width: 333px;
  }
`

const JobLink = styled(Link)`
  display: flex;
  height: 74px;
  padding: 0 20px;
  align-items: center;
  box-sizing: border-box;
  font-size: 20px;
  position: relative;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    background: #fff;
    z-index: -1;
    border: 1px solid #000;
    box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.1);
    opacity: 0;
  }

  &:hover {
    font-weight: 700;

    &:after {
      opacity: 1;
    }
  }
`

const ContactLine = styled.p`
  font-size: 20px;
  margin-top: 35px;
  line-height: 32px;

  br {
    display: none;
  }

  a {
    background: #fbd65d;
    border: 1px solid rgba(13, 13, 13, 0.1);
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.1);
    border-radius: 19px;
    padding: 8px 12px;
    margin: 0 8px;

    &:hover {
      box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 1);
    }
  }

  @media screen and (max-width: 980px) {
    br {
      display: initial;
    }
  }

  @media screen and (max-width: 640px) {
    line-height: 46px;
  }
`

export default function CalendarPage({ pageContext }) {
  const { jobCategories } = pageContext

  return (
    <>
      <SEO
        title="Career opportunities at Checkin.com - We're hiring!"
        description="Checkin.com Group AB - View open positions and join our fully remote team!"
        canonical="/careers/"
        ogImage="/images/og.jpg"
      />
      <PageContainer>
        <Sectionheader>
          <H1>Career opportunities</H1>
          <SectionDescription>
            We’re always looking for smart people who
            <br />
            aren't afraid of doing new things
          </SectionDescription>

          <ContactLine>
            Drop a line to{" "}
            <a href="mailto:join@checkin.com">join@checkin.com</a>
            <br /> for spontanious application
          </ContactLine>
        </Sectionheader>

        {Object.keys(jobCategories).map(category => (
          <CategoryContainer>
            <H2>{jobCategories[category].name}</H2>
            <Table>
              <tbody>
                {jobCategories[category].jobs.map(job => (
                  <tr>
                    <td>
                      <JobLink
                        to={`/careers/${job.slug}`}
                        state={{
                          modal: true,
                        }}
                      >
                        {job.title}
                      </JobLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CategoryContainer>
        ))}
      </PageContainer>
    </>
  )
}
