import React from "react"
import styled, { css } from "styled-components"

import Tag from "./tag"

import tags from "./tags"

const CustomTag = styled(Tag)`
  margin-bottom: 12px;
  transition: opacity 0.5s ease;

  ${props =>
    props.selected &&
    css`
      opacity: 1 !important;
    `}

  &:hover {
    opacity: 1 !important;
  }

  @media screen and (max-width: 980px) {
    margin: 0 8px;
  }

  @media screen and (max-width: 640px) {
    margin: 0 4px;

    &:first-child {
      margin-left: 0;
    }
  }
`

const AllTag = styled(CustomTag)`
  background: #fff !important;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: sticky;
  top: 150px;
  margin-left: 48px;

  ${props =>
    props.highLightSelected &&
    css`
      ${CustomTag} {
        opacity: 0.3;
      }
    `}

  @media screen and (max-width: 980px) {
    order: 1;
    margin-left: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;
    position: static;
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin: 12px 0;

  @media screen and (max-width: 980px) {
    margin: 0 16px 0 0;
    font-size: 16px;
    font-weight: 400;
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 12px;
    font-size: 16px;
  }
`

const TagsContainer = styled.div`
  display: contents;

  &:hover {
    ${CustomTag} {
      opacity: 0.3;
    }
  }

  @media screen and (max-width: 640px) {
    display: flex;
  }
`

const Topics = ({ selected = null, onSelect = () => {} }) => {
  return (
    <Container highLightSelected={selected !== null}>
      <Title>Filter by</Title>

      <TagsContainer>
        {Object.keys(tags).map((tagName, index) => (
          <CustomTag
            onClick={() => onSelect(tagName.toLowerCase())}
            key={index}
            selected={selected === tagName.toLowerCase()}
          >
            {tagName}
          </CustomTag>
        ))}
        {selected !== null && (
          <AllTag onClick={() => onSelect(null)}>All</AllTag>
        )}
      </TagsContainer>
    </Container>
  )
}

export default Topics
