import styled from "styled-components"

const ActionButton = styled.button`
  background: #ffd63e;
  border: 1px solid #000;
  outline: none;
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.1);
  padding: 18px 40px;
  font-size: 18px;
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &:hover {
    box-shadow: 5px 5px 0 0 #000;
  }
`

export default ActionButton
