import React, { useEffect, useState } from "react"
import PostTemplate from "../../components/cision/post.js"


export default function Post({ location }) {
  const [post, setPost] = useState(null)

  useEffect(() => {
    if (location && location.state) {
      setPost(location.state.post)
    } else {
      window.location = '/newsroom/'
    }
  })

  return post ? (<PostTemplate apiPost={post} />) : null
}

