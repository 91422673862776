import React, { useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"
import styled, { css } from "styled-components"
import { rgba } from "polished"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"
import { format } from "date-fns"

import Grid from "../blog/post-grid"
import PostHTML from "../blog/post-html"
import PostCard from "./post-card"
import SEO from "../seo"


import arrowBack from "../../assets/images/arrow-back.svg"
const slugify = require("slugify")

const Tag = styled.span`
  border-radius: 12px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px 10px 0;
  border: 1px solid #000;
  font-size: 10px;
  text-transform: uppercase;
  background: #ddeff4;
  position: relative;
  box-sizing: border-box;
  line-height: 10px;
  ${props =>
    props.selected &&
    css`
      padding-left: 26px;
      &::after {
        content: "";
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #000;
        position: absolute;
        align-self: flex-start;
        transform: translateX(-16px);
      }
    `}
  @media screen and (max-width: 640px) {
    font-size: 10px;
  }
`

const Overlay = styled.div`
  backdrop-filter: blur(6px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5000;
  background: ${rgba("#F9F9F8", 0.9)};
  @media screen and (max-width: 640px) {
    background: #f9f9f8;
    backdrop-filter: none;
  }
`

const CustomLink = styled(Link)`
  width: 27px;
  height: 18px;
  background: url(${arrowBack}) no-repeat center;
  position: absolute;
  top: 40px;
  left: 40px;
  @media screen and (max-width: 640px) {
    left: 20px;
    top: 20px;
  }
`

const Container = styled.div`
  grid-area: post;
  padding: 70px 0;
  background: #fff;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  pointer-events: all;
  @media screen and (max-width: 640px) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2000;
    border: none;
    padding-bottom: 40px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100vw;
    max-width: 100vw;
    &:after {
      content: "";
      width: 45px;
      height: 1px;
      background: #000;
      position: absolute;
      bottom: 0;
    }
  }
`

const PostContainer = styled.div`
  width: 555px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 980px) {
    width: 500px;
  }
  @media screen and (max-width: 640px) {
    width: 336px;
  }
`

const Title = styled.h1`
  font-size: 40px;
  line-height: 48px;
  margin-top: 24px;
  text-align: center;
  @media screen and (max-width: 640px) {
    font-size: 30px;
    line-height: 40px;
  }
`

const Separator = styled.div`
  width: 68px;
  height: 1px;
  background: #000;
  margin: 24px 0;
`

const Meta = styled.p`
  font-size: 16px;
  color: #7d7d7d;
  text-align: center;
  line-height: 24px;
`

const NextPreviousPosts = styled.div`
  margin-top: 40px;
  grid-area: next-previous;
  display: flex;
  pointer-events: all;
  & > a {
    flex: 1;
    &:first-child {
      transform: translate3d(5px, 0, 0);
    }
    &:last-child {
      transform: translate3d(-5px, 30px, 0);
    }
  }
  @media screen and (max-width: 640px) {
    flex-direction: column;
    & > a {
      transform: translateX(-7px) !important;
    }
  }
`

const Post = ({ location, pageContext, apiPost }) => {
  const containerRef = useRef()
  const post = apiPost ? apiPost : pageContext.release
  const nextPost = apiPost ? null : pageContext.nextRelease
  const previousPost = apiPost ? null : pageContext.previousRelease

  useEffect(() => {
    const onPageClick = e => {
      if ([...e.target.children].includes(containerRef.current))
        navigate("/newsroom/")
    }

    window.addEventListener("click", onPageClick)

    return () => window.removeEventListener("click", onPageClick)
  }, [])

  return (
    <>
      <SEO
        title={post.Title}
        description={post.Intro}
        canonical={`/newsroom/${slugify(post.Title + post.PublishDate, { replacement: '_', remove: /[*+~.()'"!:@]/g })}/`}
        ogImage="/images/og.jpg"
      />

      <ModalRoutingContext.Consumer>
        {({ modal }) => (
          <>
            {!modal && (
              <Overlay
                onClick={() => {
                  window.location = "/investors/press/"
                }}
              />
            )}

            <Grid style={{ zIndex: "6000" }}>
              <Container ref={containerRef}>
                <CustomLink
                  to="/investors/press"
                  onClick={e => {
                    if (modal) {
                      e.preventDefault()
                      window.history.back()
                    }
                  }}
                />
                <PostContainer>
                  <Tag>NewsFeed</Tag>
                  <Title>{post.Title}</Title>
                  <Separator />
                  <Meta>
                    Published{" "}
                    {format(new Date(post.PublishDate), "yyyy-MM-dd HH:mm")}{" "}
                    <br />
                  </Meta>
                  <PostHTML
                    dangerouslySetInnerHTML={{ __html: post.HtmlIntro }}
                  />
                  <PostHTML
                    dangerouslySetInnerHTML={{ __html: post.HtmlBody }}
                  />
                </PostContainer>
              </Container>

              {(previousPost || nextPost) && (
                <NextPreviousPosts>
                  {previousPost && (
                    <PostCard post={previousPost} minimized previous replace />
                  )}
                  {nextPost && <PostCard post={nextPost} minimized replace />}
                </NextPreviousPosts>
              )}
            </Grid>
          </>
        )}
      </ModalRoutingContext.Consumer>
    </>
  )
}

export default Post
