import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

import arrowIcon from "../../assets/images/arrow-bold.svg"
import useScroll from "../../hooks/use-scroll"

import InvestorsNavbar from "../../components/investors-navbar"
import SEO from "../../components/seo"
import birgitta from "../../assets/images/birgitta.png"
import anders from "../../assets/images/anders.png"
import kristoffer from "../../assets/images/kristoffer.png"
import kristoffer2 from "../../assets/images/kristoffer2.png"
import martin from "../../assets/images/martin.png"
import christian from "../../assets/images/christian.png"
import maria from "../../assets/images/maria.png"
import michal from "../../assets/images/michal.png"
import pdfIcon from "../../assets/images/pdf-icon.svg"
import fileIcon from "../../assets/images/file-icon.svg"
import christer from "../../assets/images/christer-fhraeus.png"
import jonas from "../../assets/images/jonas.png"
import placeholder from "../../assets/images/placeholder.png"

import {
  H2,
  H4,
  Text,
  Sectionheader,
  PageContainer,
  TextContainer,
} from "../../components/common"

const CustomPageContainer = styled(PageContainer)`
  padding-bottom: 600px;
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 254px 843px 1fr;
  margin-top: 60px;
  width: 100%;

  ${H2} {
    text-align: center;
  }

  @media screen and (max-width: 980px) {
    grid-template-columns: 1fr;
  }
`

const NavigationContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1;
  position: sticky;
  top: 150px;

  @media screen and (max-width: 980px) {
    display: none;
  }
`

const NavigationButton = styled(Link)`
  font-size: 16px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 20px;
  cursor: pointer;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    right: 0;
    height: 100%;
    width: 50px;
    background: url(${arrowIcon}) no-repeat center;
    opacity: 0;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    background: #fff;
    z-index: -1;
    box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #000;
    opacity: 0;
  }

  ${props =>
    props.active &&
    css`
      font-weight: 700;

      &:after,
      &:before {
        opacity: 1;
      }
    `}
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 60px;

  a:first-of-type {
    transform: translateY(-160px);
  }

  & > h2 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: #535353;
    margin-bottom: 45px;

    ${props =>
      props.reverseHeader &&
      css`
        text-align: right;
      `}
  }

  @media screen and (max-width: 980px) {
    padding-left: 0;
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 0;

    & > h2 {
      margin-bottom: 100px;

      ${props =>
        props.reverseHeader &&
        css`
          text-align: left;
        `}
    }
  }
`

const PictureBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 200px;
  background: #fff;
  position: relative;
  justify-content: center;
  padding: 32px;
  box-sizing: border-box;
  margin-bottom: 126px;
  position: absolute;
  right: 70px;
  top: -100px;
  background: ${props => `url(${props.picture})`} no-repeat center/cover;
  border-radius: 50%;

  @media screen and (max-width: 980px) {
    width: 160px;
    height: 160px;
    right: 40px;
    top: -80px;
  }

  @media screen and (max-width: 640px) {
    width: 140px;
    height: 140px;
    top: -70px;
  }
`

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  border: 1px solid #000000;
  position: relative;
  justify-content: center;
  padding: 32px;
  box-sizing: border-box;
  margin-bottom: 180px;

  div.info-text {
    display: contents;
  }

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 6px;
  }

  & > span {
    color: #545454;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 33px;
    margin-top: 30px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 16px;

    span {
      font-weight: 700;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${props =>
    props.reverse &&
    css`
      h3,
      & > span {
        margin-left: 240px;
      }

      & > span {
        margin-bottom: 60px;
      }

      ${PictureBox} {
        right: auto;
      }

      @media screen and (max-width: 980px) {
        h3,
        & > span {
          margin-left: 180px;
        }
      }

      @media screen and (max-width: 640px) {
        h3,
        & > span {
          margin-left: 0;
        }
      }
    `}

  &::after {
    content: "";
    position: absolute;
    border: 1px solid #000000;
    border-left: none;
    transform: skewY(30deg);
    transform-origin: left bottom;
    height: calc(100% + 2px);
    width: 31px;
    top: -1px;
    left: calc(100% + 1px);
    pointer-events: none;
    background: ${props => props.color};
    box-sizing: border-box;

    @media screen and (max-width: 640px) {
      width: 15px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    border: 1px solid #000000;
    border-left: 2px solid #000;
    border-top: none;
    transform: skew(60deg);
    transform-origin: right top;
    height: 18px;
    width: calc(100% + 2px);
    top: calc(100% + 1px);
    right: -1px;
    pointer-events: none;
    background: ${props => props.color};
    box-sizing: border-box;

    @media screen and (max-width: 640px) {
      height: 9px;
    }
  }

  @media screen and (max-width: 980px) {
    h3 {
      font-size: 24px;
      font-weight: 900;
      margin-bottom: 6px;
    }

    & > span {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 24px;
    }
  }

  @media screen and (max-width: 640px) {
    padding: 90px 20px 32px;
    margin-bottom: 24px;

    h3 {
      font-size: 24px;
      font-weight: 900;
    }

    div.info-text {
      display: none;
    }

    ${PictureBox} {
      right: auto;
      left: 20px;
    }

    & > span {
      margin-bottom: 0;
    }

    ${props =>
      props.padded &&
      css`
        div.info-text {
          display: flex;
        }
      `}
  }
`

const InfoTextMobile = styled.div`
  margin-bottom: 120px;
  display: none;

  @media screen and (max-width: 640px) {
    display: block;
  }

  h3 {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 6px;
  }

  & > span {
    color: #545454;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 33px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 16px;

    span {
      font-weight: 700;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const AuditorSummary = styled.p`
  line-height: 28px;
  margin-top: 14px;

  @media screen and (max-width: 640px) {
    width: auto;
    br {
      display: block;
    }
  }
`

const seniorExecutives = [
  {
    name: "Christian Karlsson",
    position: "Acting CEO since 2024. Previously Chief Commercial Officer (CCO) since 2018.",
    born: "1983",
    education:
      "Working and investing in digital/payment corporations since 2012. Director of Payments at LeoVegas Group (2013-2017) and Head of Customer Operations at Gaming Innovation Group (2017-2018).",
    otherAssignments: "CEO, Member of the Board and owner of Ammis OÜ.",
    holdings: "307,984 shares.",
    color: "#D098D7",
    picture: christian,
  },
  {
    name: "Martin Bäuml",
    position: "Chief Financial Officer (CFO)  since 2020.",
    born: "1980",
    education:
      "Master's degree in Business Administration, Stockholm School of Economics (2005). Previous experience from investment banking at Credit Suisse (2007–2010), Citi (2010–2015) and Handelsbanken (2015–2018).",
    otherAssignments: "CEO and owner of Mabaco LLC.",
    holdings: "199,616 shares and 19,999 warrants.",
    color: "#D098D7",
    reverse: true,
    picture: martin,
  },

]

const boardOfdirectors = [
  {
    name: "Kristoffer Cassel",
    position:
      "Chairman of the Board since 2024. Member of the Board since 2017.",
    born: "1982",
    education:
      "Courses in Business and Economics, Stockholm School of Economics. Previous experience from leading positions within Kindred Group (2009–2011), Wonga Group (2011–2013) and Klarna Bank (2013–2016). Kristoffer Cassel is also co-founder of Checkin.com Group.",
    otherAssignments: "CEO, Member of the Board and owner of Lessac AB.",
    holdings: "6,592,685 shares.",
    dependency:
      "Dependent in relation to major shareholders, the company and management.",
    color: "#C0EAD6",
    picture: kristoffer,
  },
  {
    name: "Christer Fåhraeus",
    position: "Member of the Board since 2023.",
    born: "1965",
    education:
      "PhD neurophysiology, MSc Bioengineering, BSc mathematics/physics, BSc medical school, Lieutenant from Swedish Army Language School (tolkskolan).",
    otherAssignments:
      "General partner FSG fond I and FSG fond II. Chairman of the Board EQL Pharma AB and Bionamic AB. Board member Melius Pharma and Airsonett AB (publ), Cellavision (publ) och Flatfrog laboratories AB (publ).",
    holdings: "1,078,036 shares.",
    dependency:
      "Independent in relation to major shareholders, the company and management.",
    color: "#C0EAD6",
    reverse: true,
    picture: christer,
  },
  {
    name: "Birgitta Hagenfeldt",
    position: "Member of the Board since 2021.",
    born: "1961",
    education:
      "Master of Business Administration, Örebro University (1986). Previous experience as Chief Financial Officer (CFO) (2008–2021), Deputy President (2017–2021) and member of the Group Management team (2008–2021) for Avanza Bank Holding and Avanza Bank.",
    otherAssignments:
      "Chairman of the board for RAM One AB and RAM Rational Asset Management AB. Member of the board of SECTRA AB, Försäkringsaktiebolaget Avanza Pension and Stabelo Group AB.",
    holdings: "17,900 shares and 9,000 warrants.",
    dependency:
      "Independent in relation to major shareholders, the company and management.",
    color: "#C0EAD6",
    picture: birgitta,
  },
  {
    name: "Maria McDonald",
    position: "Member of the Board since 2017.",
    born: "1981",
    education:
      "Bachelor's degree in Law Gothenburg University (2006). Previous experience as General Counsel Kindred Group (2012–2014) and General Counsel and Member of the Management Team at Lagardère Sports Scandinavia (2014–2017). Maria McDonald is also Head of Legal at Checkin.com Group and partner at the law firm Nordic Legal & Compliance AB.",
    otherAssignments:
      "Chairman of the Board of McD Sports and Legal AB. CEO and Member of the Board of Nordic Legal & Compliance AB. Member of the Board and partner of Nordic Gambling Aps.",
    holdings: "80,000 shares and 9,000 warrants.",
    dependency:
      "Independent in relation to major shareholders, Dependent in relation to the company and management.",
    color: "#C0EAD6",
    reverse: true,
    picture: maria,
  },
  {
    name: "Michal Stala",
    position: "Member of the Board since 2020.",
    born: "1980",
    education:
      "Master of Science in Engineering in Computer Technology, Lund University (1999). Previous experience as CEO for MistBase (2015–2017).",
    otherAssignments:
      "Co-founder/CEO Peak Energy AB, Chairman of the board of Sanctify Financial Technologies AB and Intuicell AB. Member of the board of Gimic AB, Alats Technologies AB, Alats AB, Mist Ventures AB and Quinary Investment AB. Founder/owner of Alats Marbella S.L.",
    holdings: "0 shares and 9,000 warrants.",
    dependency:
      "Dependent in relation to major shareholders, Independent in relation to the company and management.",
    color: "#C0EAD6",
    picture: michal,
  },
  {
    name: "Jonas Strömberg",
    position: "Member of the Board since 2023.",
    born: "1981",
    education:
      "Master Degree of Science in Business Administration and Economics, Bachelor Degree of Social Science with a Major in Economics, Stockholm University School of Business. More than 10 years experience in Banking/Investment Banking with focus in Institutional Equities and Securities Financing. Jonas has also held a position as Funding Manager at The Swedish National Debt Office.",
    otherAssignments:
      "Investment Manager at Erik Selin Fastigheter AB and Portfolio Manager at ES Aktiehandel AB (shareholder in Checkin.com Group AB) and Gyllene Aktier 2021 (shareholder in Checkin.com Group AB). Member of the Board at Carlsson & Norén Asset Management and Acroud. Member of the Nomination Committee in Resurs Bank.",
    holdings: "16,000 shares.",
    dependency:
      "Independent in relation to major shareholders, the company and management.",
    color: "#C0EAD6",
    reverse: true,
    picture: jonas,
  },
]

const Navigation = ({ index = 0, onNavigate = () => {} }) => {
  return (
    <NavigationContainer>
      <NavigationButton
        to="#directors"
        active={index === 0}
        onClick={() => onNavigate(0)}
      >
        Board of directors
      </NavigationButton>
      <NavigationButton
        to="#executives"
        active={index === 1}
        onClick={() => onNavigate(1)}
      >
        Senior executives
      </NavigationButton>
      <NavigationButton
        to="#auditor"
        active={index === 2}
        onClick={() => onNavigate(2)}
      >
        Auditor
      </NavigationButton>
      <NavigationButton
        to="#adviser"
        active={index === 3}
        onClick={() => onNavigate(3)}
      >
        Certified Adviser
      </NavigationButton>
      <NavigationButton
        to="#general-meetings"
        active={index === 4}
        onClick={() => onNavigate(4)}
      >
        General meetings
      </NavigationButton>
      <NavigationButton
        to="#annual-general-meeting"
        active={index === 5}
        onClick={() => onNavigate(5)}
      >
        AGM 2024
      </NavigationButton>
      <NavigationButton
        to="#articles-of-association"
        active={index === 6}
        onClick={() => onNavigate(6)}
      >
        Articles of association
      </NavigationButton>
      <NavigationButton
        to="#nomination_committee"
        active={index === 7}
        onClick={() => onNavigate(7)}
      >
        Nomination Committee
      </NavigationButton>
    </NavigationContainer>
  )
}

const InfoBoxComponent = ({ data, children, padded }) => {
  return (
    <>
      <InfoBox color={data.color} reverse={data.reverse} padded={padded}>
        <PictureBox picture={data.picture} />
        {data.name && <h3>{data.name}</h3>}
        {data.position && <span>{data.position}</span>}
        <div className="info-text">
          {data.born && (
            <p>
              <span>Born: </span>
              {data.born}
            </p>
          )}
          {data.education && (
            <p>
              <span>Education and Experience: </span>
              {data.education}
            </p>
          )}
          {data.otherAssignments && (
            <p>
              <span>Other assignments: </span>
              {data.otherAssignments}
            </p>
          )}
          {data.holdings && (
            <p>
              <span>Holdings in the Company: </span>
              {data.holdings}
            </p>
          )}
          {data.dependency && (
            <p>
              <span>Position of dependency: </span>
              {data.dependency}
            </p>
          )}
          {children}
        </div>
      </InfoBox>

      <InfoTextMobile>
        {data.born && (
          <p>
            <span>Born: </span>
            {data.born}
          </p>
        )}
        {data.education && (
          <p>
            <span>Education and Experience: </span>
            {data.education}
          </p>
        )}
        {data.otherAssignments && (
          <p>
            <span>Other assignments: </span>
            {data.otherAssignments}
          </p>
        )}
        {data.holdings && (
          <p>
            <span>Holdings in the Company: </span>
            {data.holdings}
          </p>
        )}
        {data.dependency && (
          <p>
            <span>Position of dependency: </span>
            {data.dependency}
          </p>
        )}
        {/* {children} */}
      </InfoTextMobile>
    </>
  )
}

const Table = styled.table`
  border-spacing: 0px;
  border-collapse: collapse;

  td {
    border: 1px solid #afafaf;
    border-left: none;
    border-right: none;

    & > div {
      display: flex;
      height: 80px;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
    }

    &:last-of-type > div {
      @media screen and (max-width: 640px) {
        height: 110px;
      }
    }
    span {
      font-size: 20px;
      font-weight: 700;
      display: block;
      margin-bottom: 0px;
      max-width: 550px;

      @media screen and (max-width: 640px) {
        font-size: 16px;
        width: 196px;
        line-height: 24px;
      }
    }

    a {
      font-size: 14px;
      color: #0070c9;
      text-decoration: underline;

      @media screen and (max-width: 980px) {
        font-size: 13px;
      }
    }
  }
`

const Header = styled(H2)`
  @media screen and (max-width: 980px) {
    font-size: 40px;
  }

  @media screen and (max-width: 640px) {
    font-size: 34px;
  }
`

const FileButton = styled.button`
  width: 250px;
  height: 100%;
  box-shadow: none;
  border: none;
  background: url(${fileIcon}) no-repeat center;
  outline: none;
  cursor: pointer;

  @media screen and (max-width: 980px) {
    width: 50px;
  }
`

const PDFButton = styled(FileButton)`
  background: url(${pdfIcon}) no-repeat center;
`

const AuditorContentContainer = styled(ContentContainer)`
  @media screen and (max-width: 640px) {
    ${InfoBox} {
      padding: 32px 20px;
    }

    h2 {
      margin-bottom: 40px;
    }
  }
`

export default function GovernancePage() {
  const [navigationIndex, setNavigationIndex] = useState(0)
  const [disableScrollTracking, setDisableScrollTracking] = useState(false)
  let scroll = useScroll()

  useEffect(() => {
    if (disableScrollTracking) return
    if (scroll < 4384) setNavigationIndex(0)
    else if (scroll > 4384 && scroll < 6841) setNavigationIndex(1)
    else if (scroll > 6841 && scroll < 7227) setNavigationIndex(2)
    else if (scroll > 7227 && scroll < 7685) setNavigationIndex(3)
    else if (scroll > 7685 && scroll < 8210) setNavigationIndex(4)
    else if (scroll > 8612 && scroll < 8983) setNavigationIndex(5)
    else if (scroll > 8983 && scroll < 9533) setNavigationIndex(6)
    else if (scroll > 9534) setNavigationIndex(7)
  }, [scroll, disableScrollTracking])

  const onNavigate = index => {
    setDisableScrollTracking(true)
    setNavigationIndex(index)
    setTimeout(() => {
      setDisableScrollTracking(false)
    }, 1500)
  }

  return (
    <>
      <SEO
        title="Checkin.com Group AB - Governance"
        description="Find information related to governance, board of directors, auditor, general meetings, and more."
        canonical="/investors/governance"
        ogImage="/images/og.jpg"
      />
      <CustomPageContainer>
        <InvestorsNavbar />
        <Sectionheader>
          <Header>Governance</Header>
        </Sectionheader>

        <GridContainer>
          <di>
            <Navigation index={navigationIndex} onNavigate={onNavigate} />
          </di>

          <div>
            <ContentContainer>
              <a id="directors"></a>
              <h2>Board of directors</h2>
              {boardOfdirectors.map((data, index) => (
                <InfoBoxComponent key={index} data={data} />
              ))}
            </ContentContainer>

            <ContentContainer>
              <a id="executives"></a>
              <h2>Senior executives</h2>
              {seniorExecutives.map((data, index) => (
                <InfoBoxComponent key={index} data={data} />
              ))}
            </ContentContainer>

            <AuditorContentContainer>
              <a id="auditor"></a>
              <h2>Auditor</h2>
              <InfoBoxComponent
                padded
                data={{
                  name: "Ludvig Kollberg",
                  color: "#B4DEEC",
                }}
              >
                <AuditorSummary>
                  The auditor of Checkin.com Group is MOORE Sweden, with Ludvig
                  Kollberg as auditor-in-charge since the 2017 annual general
                  meeting. Ludvig Kollberg is an authorized public accountant
                  and member of FAR.
                </AuditorSummary>
              </InfoBoxComponent>
            </AuditorContentContainer>

            <AuditorContentContainer>
              <a id="adviser"></a>
              <h2>Certified Adviser</h2>
              <InfoBoxComponent
                padded
                data={{
                  name: "Carnegie Investment Bank AB (publ)",
                  color: "#B4DEEC",
                }}
              >
                <AuditorSummary>
                  <span style={{ whiteSpace: 'nowrap' }}> tel +46 (0)73 856 42 65</span>
                  <br />
                  certifiedadviser@carnegie.se
                </AuditorSummary>
              </InfoBoxComponent>
            </AuditorContentContainer>

            <TextContainer>
              <a id="general-meetings"></a>
              <H2>General meetings</H2>

              <H4>General shareholder meeting</H4>
              <Text>
                Pursuant to the Swedish Companies Act, the general shareholder
                meeting is the Company’s highest decision-making body. At the
                general meeting, the shareholders exercise their voting rights
                on key issues, such as the adoption of income statements and
                balance sheets, appropriation of the Company’s profit, discharge
                from liability of board members and the CEO, election of board
                members and auditors and remuneration of the board and auditors.
                <br />
                <br />
                In addition to the Annual General Meeting (AGM), Extraordinary
                General Meetings (EGMs) may be held. In accordance with
                Checkin.com Group AB’s articles of association, notice to Annual
                General Meetings and Extraordinary General Meetings is made by
                announcement in the Official Swedish Gazette (Sw. Post och
                Inrikes Tidningar) and by making the notice available on
                Checkin.com Group’s website. An announcement that notice has
                been issued is also published in Dagens Industri.
              </Text>

              <H4>Right to attend general shareholder meetings</H4>
              <Text>
                All shareholders who are directly registered in the share
                register maintained by Euroclear six weekdays before the general
                shareholder meeting and have notified the Company of their
                intention to participate (with potential assistants) in the
                general meeting not later than the date stated in the notice
                convening the general meeting have the right to attend the
                general meeting and vote for the number of shares they hold.
                Shareholders may attend the general shareholder meeting in
                person or by proxy and may also be accompanied by a maximum of
                two assistants. Shareholders can normally register for the
                general shareholder meeting in several different ways stated in
                the convening notice for the meeting.
              </Text>

              <H4>Shareholder initiatives</H4>
              <Text>
                Shareholders who wish to have a matter addressed at the general
                shareholder meeting must submit a written request to the Board
                of Directors. The request must normally have been received by
                the board not later than seven weeks before the general
                shareholder meeting.
              </Text>
            </TextContainer>
            <TextContainer>
              <a id="annual-general-meeting"></a>
              <H2>Annual General Meeting 2024</H2>

              <Table>
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <span>Form of power of attorney (Eng)</span>
                        <FileButton
                          onClick={() =>
                            window.open(
                              "/documents/2023/Form_of_power_of_attorney_Eng.docx",
                              "_blank"
                            )
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <span>Form of power of attorney (Swe)</span>
                        <FileButton
                          onClick={() =>
                            window.open(
                              "/documents/2023/Form_of_power_of_attorney_Swe.docx",
                              "_blank"
                            )
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <span>Notice of the Annual General Meeting</span>
                        <FileButton
                          onClick={() =>
                            window.open(
                              "/documents/2024/Notice_of_the_Annual_General_Meeting_2024.pdf",
                              "_blank"
                            )
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <span>
                          The Nomination Committee´s motivated opinion (Swe)
                        </span>
                        <FileButton
                          onClick={() =>
                            window.open(
                              "/documents/2024/Motiverat_Yttrande_Valberedningen_2024.pdf",
                              "_blank"
                            )
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <span>
                          Proposals for resolutions at the Annual General
                          Meeting (Swe)
                        </span>
                        <FileButton
                          onClick={() =>
                            window.open(
                              "/documents/2024/Proposals_for_Resolutions_at_the_AGM_(SWE).pdf",
                              "_blank"
                            )
                          }
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div>
                        <span>Resolutions at Checkin.com Group Annual General Meeting 2024 (Swe)</span>
                        <FileButton
                          onClick={() =>
                            window.open(
                              "/documents/2024/Annual_General_Meeting_Checkin.com_Group_2024.pdf",
                              "_blank"
                            )
                          }
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div>
                        <span>Minutes from Checkin.com Group Annual General Meeting 2024 (Swe)</span>
                        <FileButton
                          onClick={() =>
                            window.open(
                              "/documents/2024/Minutes_from_Checkin.com_Group_Annual_General_Meeting_2024.pdf",
                              "_blank"
                            )
                          }
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </TextContainer>

            <TextContainer>
              <a id="articles-of-association"></a>
              <H2>Articles of association</H2>

              <Table>
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <span>Bolagsordning - 559096-3087</span>
                        <PDFButton
                          onClick={() =>
                            window.open(
                              "/documents/Bolagsordning_-_559096-3087.pdf",
                              "_blank"
                            )
                          }
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </TextContainer>

            <TextContainer>
              <a id="nomination_committee"></a>
              <H2>
                Nomination Committee in Checkin.com Group AB (publ) ahead of
                Annual General Meeting 2023
              </H2>

              <Text>
                In accordance with the decision taken by Checkin.com Group AB
                (publ)'s Annual General Meeting in 2021, the three largest
                shareholders in the company have the right to each appoint a
                member of the Nomination Committee. The three largest
                shareholders in the company have now each appointed a member,
                whereby the Nomination Committee for Checkin.com Group AB's
                Annual General Meeting 2023 has the following members:
                <br />
                <br />
                <li>Björn Frössevi - appointed by Lessac AB</li>
                <li>Maria McDonald - appointed by Alexey Kuznetsov AB</li>
                <li>Johan Qviberg - appointed by Quinary Investment AB</li>
              </Text>

              <Text>
                Shareholders who wish to contact the Nomination Committee may do
                so in writing to:
              </Text>

              <Text>
                Checkin.com Group AB <br />
                Att: Nomination Committee <br />
                Engelbrektsplan 2 <br />
                114 34 Stockholm
              </Text>
            </TextContainer>
          </div>
        </GridContainer>
      </CustomPageContainer>
    </>
  )
}
