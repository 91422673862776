exports.components = {
  "component---src-components-blog-page-js": () => import("./../../../src/components/blog/page.js" /* webpackChunkName: "component---src-components-blog-page-js" */),
  "component---src-components-cision-post-js": () => import("./../../../src/components/cision/post.js" /* webpackChunkName: "component---src-components-cision-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/_careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/_index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investors-calendar-js": () => import("./../../../src/pages/investors/calendar.js" /* webpackChunkName: "component---src-pages-investors-calendar-js" */),
  "component---src-pages-investors-governance-js": () => import("./../../../src/pages/investors/governance.js" /* webpackChunkName: "component---src-pages-investors-governance-js" */),
  "component---src-pages-investors-index-js": () => import("./../../../src/pages/investors/index.js" /* webpackChunkName: "component---src-pages-investors-index-js" */),
  "component---src-pages-investors-ipo-js": () => import("./../../../src/pages/investors/ipo.js" /* webpackChunkName: "component---src-pages-investors-ipo-js" */),
  "component---src-pages-investors-press-js": () => import("./../../../src/pages/investors/_press.js" /* webpackChunkName: "component---src-pages-investors-press-js" */),
  "component---src-pages-investors-reports-js": () => import("./../../../src/pages/investors/reports.js" /* webpackChunkName: "component---src-pages-investors-reports-js" */),
  "component---src-pages-investors-share-js": () => import("./../../../src/pages/investors/share.js" /* webpackChunkName: "component---src-pages-investors-share-js" */),
  "component---src-pages-newsroom-mentions-js": () => import("./../../../src/pages/newsroom/_mentions.js" /* webpackChunkName: "component---src-pages-newsroom-mentions-js" */),
  "component---src-pages-newsroom-mentions-old-js": () => import("./../../../src/pages/newsroom/mentions_old.js" /* webpackChunkName: "component---src-pages-newsroom-mentions-old-js" */),
  "component---src-pages-newsroom-post-js": () => import("./../../../src/pages/newsroom/post.js" /* webpackChunkName: "component---src-pages-newsroom-post-js" */)
}

