const LOCAL_HOSTNAME = ["localhost"]
const DEVELOPMENT_HOSTNAME = ["develop.groups.checkin.com"]
const STAGING_HOSTNAME = ["staging.groups.checkin.com"]
const PRODUCTION_HOSTNAME = ["groups.checkin.com"]

const localUrls = {
  scanner: "https://dev-scanner.regily.io/v1",
  regilyAPI: "https://staging-api.regily.com",
  customizationAPI: "https://dev-customizations.regily.io",
  pluginDownload: "http://dev-plugins.regily.io/flowbrain-latest.zip",
  platform: "https://dev-platform.regily.io",
  metadata: "https://dev-metadata.regily.io/v1",
  statistics: "https://dev-statistics.regily.io",
  AUTH0_DOMAIN: "regily.eu.auth0.com",
  AUTH0_CLIENTID: "Lm3cWgR1jOsxLCsqAtNVXBbPiVXmT3T7",
  AUTH0_CALLBACK: "http://localhost:8000/auth",
  AUTH0_LOGOUT_REDIRECT: "http://localhost:8000",
  stripeKey: "pk_test_xRS1tmFuE2etZubFaEbdqNsq00MGMqSL9T",
  paymentsAPI: "http://localhost:3030",
  api: "http://localhost:3030/api/v1",
  remoteTool: "https://dev-verify-remote.regily.io/verify-remote",
  revealAPI:
    "https://reveal.clearbit.com/v1/companies/reveal?authorization=pk_5adb5b2227763b935eef8dd79f4dd817",
}

const developmentUrls = {
  scanner: "https://dev-scanner.regily.io/v1",
  regilyAPI: "https://staging-api.regily.com",
  customizationAPI: "https://dev-customizations.regily.io",
  pluginDownload: "https://dev-plugins.regily.io/flowbrain-latest.zip",
  platform: "https://dev-platform.regily.io",
  metadata: "https://dev-metadata.regily.io/v1",
  statistics: "https://dev-statistics.regily.io",
  AUTH0_DOMAIN: "regily.eu.auth0.com",
  AUTH0_CLIENTID: "Lm3cWgR1jOsxLCsqAtNVXBbPiVXmT3T7",
  AUTH0_CALLBACK: "http://localhost:8000/auth",
  AUTH0_LOGOUT_REDIRECT: "http://localhost:8000",
  stripeKey: "pk_test_xRS1tmFuE2etZubFaEbdqNsq00MGMqSL9T",
  paymentsAPI: "https://dev-payments.regily.io",
  api: "https://develop.checkin.com/api/v1",
  remoteTool: "https://dev-verify-remote.regily.io/verify-remote",
  revealAPI:
    "https://reveal.clearbit.com/v1/companies/reveal?authorization=pk_5adb5b2227763b935eef8dd79f4dd817",
}

const stagingUrls = {
  scanner: "https://staging-scanner.regily.io/v1",
  regilyAPI: "https://staging-api.regily.com",
  customizationAPI: "https://staging-customizations.regily.io",
  pluginDownload: "https://staging-plugins.regily.io/flowbrain-latest.zip",
  platform: "https://staging-platform.regily.io",
  metadata: "https://staging-metadata.regily.io/v1",
  statistics: "https://staging-statistics.regily.io",
  AUTH0_DOMAIN: "regily.eu.auth0.com",
  AUTH0_CLIENTID: "Lm3cWgR1jOsxLCsqAtNVXBbPiVXmT3T7",
  AUTH0_CALLBACK: "https://staging-flowbrain.regily.com/auth",
  AUTH0_LOGOUT_REDIRECT: "https://staging-flowbrain.regily.com",
  stripeKey: "pk_test_xRS1tmFuE2etZubFaEbdqNsq00MGMqSL9T",
  paymentsAPI: "https://staging-payments.regily.io",
  api: "https://develop.checkin.com/api/v1",
  remoteTool: "https://dev-verify-remote.regily.io/verify-remote",
  revealAPI:
    "https://reveal.clearbit.com/v1/companies/reveal?authorization=pk_5adb5b2227763b935eef8dd79f4dd817",
}

const productionUrls = {
  scanner: "https://scanner.regily.io/v1",
  regilyAPI: "https://staging-api.regily.com",
  customizationAPI: "https://customizations.regily.io",
  pluginDownload: "https://plugins.regily.io/flowbrain-latest.zip",
  platform: "https://platform.regily.io",
  metadata: "https://metadata.regily.io/v1",
  statistics: "https://statistics.regily.io",
  AUTH0_DOMAIN: "regily.eu.auth0.com",
  AUTH0_CLIENTID: "Lm3cWgR1jOsxLCsqAtNVXBbPiVXmT3T7",
  AUTH0_CALLBACK: "https://regily.com/auth",
  AUTH0_LOGOUT_REDIRECT: "https://regily.com",
  stripeKey: "pk_test_xRS1tmFuE2etZubFaEbdqNsq00MGMqSL9T",
  paymentsAPI: "https://payments.regily.io",
  api: "/api/v1",
  remoteTool: "https://verify-remote.regily.io/verify-remote",
  revealAPI:
    "https://reveal.clearbit.com/v1/companies/reveal?authorization=pk_5adb5b2227763b935eef8dd79f4dd817",
}

const serviceURL = () => {
  if (typeof window === "undefined") return {}
  const HOST = window.location.host
  const PORT = HOST.split(":")[1]
  const HOSTNAME = window.location.hostname

  if (LOCAL_HOSTNAME.includes(HOSTNAME)) {
    switch (PORT) {
      case "8000":
        return localUrls

      case "8080":
        return productionUrls

      default:
        return localUrls
    }
  }

  if (DEVELOPMENT_HOSTNAME.includes(HOSTNAME)) return developmentUrls

  if (STAGING_HOSTNAME.includes(HOSTNAME)) return stagingUrls

  if (PRODUCTION_HOSTNAME.includes(HOSTNAME)) return productionUrls

  return developmentUrls
}

export default serviceURL
