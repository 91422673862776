import styled from "styled-components"
import _get from "lodash.get"

import tags from "./tags"

const Tag = styled.span`
  width: 120px;
  border-radius: 12px;
  height: 24px;
  min-height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  font-size: 10px;
  line-height: 1px;
  font-family: 'Object Sans', sans-serif !important;
  text-transform: uppercase;
  background: ${props =>
    _get(tags, `${props.children.toLowerCase()}.colors.tag`)};
  position: relative;
  box-sizing: border-box;
  cursor: pointer;

  @media screen and (max-width: 980px) {
    width: auto;
    padding: 0 5px;
  }

  @media screen and (max-width: 640px) {
    font-size: 10px;
  }
`

export default Tag
