import React, { useState, useEffect } from "react"
import styled, { createGlobalStyle } from "styled-components"
import { Link, graphql } from "gatsby"
import { rgba } from "polished"
import { format } from "date-fns"
import axios from "axios"

import Video from "../components/video"
import IllustratedFeature from "../components/product/illustrated-feature"
import indexIllustration from "../assets/images/index-illustration.svg"
import Software from "../assets/images/software.inline.svg"
import Email from "../assets/images/email.inline.svg"
import Slack from "../assets/images/slack.inline.svg"
import DataPoints from "../assets/images/data.inline.svg"
import Code from "../assets/images/code.inline.svg"
import checkinLogo from "../assets/images/checkin-logo.svg"
import lastWebcast from "../assets/images/last_webcast.svg"
import lastReport from "../assets/images/last_report.svg"
import companyPresentation from "../assets/images/companyPresentation.svg"
import financialTarget from "../assets/images/financialTarget.svg"
import Download from "../assets/images/download2.inline.svg"
import Conversion from "../assets/images/index/conversion.svg"
import DataDriven from "../assets/images/index/data-driven.svg"
import Global from "../assets/images/index/global-compliant.svg"
import Lizzard from "../assets/images/index/lizzard.svg"
import Rabbit from "../assets/images/index/Rabbit-star.svg"
import Star from "../assets/images/index/star-coin.svg"
import SEO from "../components/seo"
import {
  H1,
  H2,
  Section,
  Sectionheader,
  SectionDescription,
  PageContainer,
} from "../components/common"
const slugify = require("slugify")


const GlobalStyle = createGlobalStyle`
  html {
    background: #f9f9f8;
  }
`

const IndexIllustrationContainer = styled.div`
  position: absolute;
  left: -10000px;
  right: -10000px;
  top: 55px;
  display: flex;
  justify-content: center;
  z-index: -1;

  @media screen and (max-width: 980px) {
    top: 250px;
    left: 0;
    right: 0;
  }

  @media screen and (max-width: 640px) {
    top: 420px;
    right: -15px;
  }
`

const IndexIllustration = styled.div`
  width: 1907px;
  height: 1220px;
  margin-left: -380px;
  background: url(${indexIllustration}) no-repeat center/contain;

  @media screen and (max-width: 980px) {
    width: 1000px;
    height: 640px;
    margin-left: -230px;
  }

  @media screen and (max-width: 640px) {
    width: 500px;
    height: 371px;
    margin-left: -110px;
    background: url(${indexIllustration}) no-repeat center/129%;
  }
`

const NavigationBlocksContainer = styled.div`
  width: 100%;
  min-height: 573px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    margin-top: 0px;
    align-items: center;
  }

  @media screen and (max-width: 640px) {
    margin-top: 0px;
  }
`

const NavigationBlock = styled.div`
  width: 362px;
  border: 1px solid #000;
  background: ${props => props.background || "#fff"};
  position: relative;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h4 {
    font-size: 34px;
    font-weight: 900;
  }

  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border: 1px dashed #000;
    top: 10px;
    left: 10px;
    z-index: -1;
  }

  @media screen and (max-width: 980px) {
    width: 100%;
    margin-bottom: 30px;

    h4 {
      font-size: 20px;
      font-weight: 900;
    }
  }
`

const NavigationBlockButton = styled(Link)`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 5px 5px 0 0 ${rgba("#000", 0.1)};
  background: ${props => props.background || "transparent"};

  &:hover {
    box-shadow: 5px 5px 0 0 ${rgba("#000", 1)};
  }
`

const NavigationBlockContent = styled.div`
  flex: 1;
  padding: 32px 0;
  display: flex;
  flex-direction: column;

  & > div {
    display: contents;
  }

  @media screen and (max-width: 980px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    & > div {
      /* flex: 50%; */
      width: 45%;
      display: flex;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;

    & > div {
      display: contents;
    }
  }
`

const NavigationBlockLink = styled(Link)`
  font-size: 20px;
  text-decoration: underline;
  display: block;
  margin-bottom: 30px;
  font-weight: 400;

  @media screen and (max-width: 980px) {
    width: 45%;
    display: inline-block;
    font-size: 16px;
  }

  @media screen and (max-width: 640px) {
    width: auto;
    display: block;
    font-size: 18px;
  }
`

const IllustratedFeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px 100px;
  margin: 90px 0;

  @media screen and (max-width: 980px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`

const JobHeader = styled.p`
  font-size: 14px;
  color: #535353;
  margin-bottom: 5px;

  span {
    text-transform: uppercase;
    font-weight: 700;
  }
`

const JobName = styled(Link)`
  color: #0d0d0d;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 28px;
`

const NumbersContainer = styled.div`
  margin-top: 660px;
  ${H2}{
    text-align: center;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 980px) {
    margin-top: 400px;
  }

  @media screen and (max-width: 640px) {
    margin-top: 265px;
  }
`

const Numbers = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 93px;

  @media screen and (max-width: 980px) {
    grid-template-columns: auto auto ;
  }

  @media screen and (max-width: 640px) {
   display: block;
  }
`
const Number = styled.div`

  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 980px) {
    &:last-child {
      grid-column-end: -2;
    }
  }

  @media screen and (max-width: 640px) {
    display: flex;
    margin-top: 50px;
    align-items: center;
    flex-direction: column;
  }
`

const VideoPlaceholder = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media screen and (max-width: 980px) {
    width: 100%;
    height: 430px;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    height: 250px;
  }
`
const LastWebcast = styled(VideoPlaceholder)`
  background: url(${lastWebcast}) no-repeat center/99%;
`

const LastReport = styled(VideoPlaceholder)`
  background: url(${lastReport}) no-repeat center/99%;
`

const CompanyPresentation = styled(VideoPlaceholder)`
  background: url(${companyPresentation}) no-repeat center/99%;
  @media screen and (max-width: 980px) {
    border: none;
  }
`

const FinancialTarget = styled(VideoPlaceholder)`
  display: flex;
  flex-direction: row;    
  height: 540px;
  border: 1px solid #000; background: #FFF;
  box-shadow: 2.226px 2.226px 0px 0px rgba(0, 0, 0, 0.10);

  @media screen and (max-width: 980px) {
    flex-direction: column;
    border: none;
    height: auto;
    padding-bottom: 20px;
  }
`


const PlayButton = styled.button`
  background: #fff;
  outline: none;
  box-shadow: none;
  width: 70px;
  height: 70px;
  border: 1px solid #000;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:after {
    content: "";
    height: 0;
    width: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 20px solid #000;
    position: absolute;
    margin-left: 6px;
  }
`

const DownloadButton = styled.div`
  background: #fff;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  span{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 21px;
    margin-right: 10px;
    text-decoration: underline;
  }
  

`


const VideosContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;

  .full-width-div {
    width: 100%; 
  }

  .next-row-div {
    width: calc(33.33% - 10px); 
    margin: 5px;
  }
  @media screen and (max-width: 980px) {
    display:grid;
    grid-template-columns: auto; 

    .full-width-div, .next-row-div  {
      width: 100%; 
      margin: 0px;
    }

  }
`;

const Content = styled.div`
  padding: 54px;
  flex: 1;
  h3{
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; 
    margin-bottom: 10px;
  }
  p {
    color: #0D0D0D;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; 
  }
  
  p.description{
    margin-bottom: 54px;
  }
  span {
    margin-right: 20px;
    margin-left: 20px;
  @media screen and (max-width: 460px) {
    margin-left: 0px;
  }
  }
  .ebitda{
    margin-top:32px;
  }
  .subHead {
    color: #0D0D0D;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
  @media screen and (max-width: 980px) {
    width: 90%;
  }
`
const Rocket = styled.div`
  flex: 1;
  background: url(${financialTarget}) no-repeat center/99%;
  height: 100%;
  @media screen and (max-width: 980px) {
    width: 100%;
    flex: 1 auto;
  }
`


export default function IndexPage({ data, location, pageContext }) {
  const { jobs } = pageContext
  const [showVideo, setShowVideo] = useState(false)
  const [id, setId] = useState(649525389)
  const [type, setType] = useState("vimeo")
  const [releases, setReleases] = useState([])
  const [report, setReport] = useState(data.strapiData["latest_annual_report"])
  const [presentation, setPresentation] = useState(data.strapiData["latest_q_webcast"])

  useEffect(() => {
    axios.get('https://strapi.regily.io/reportspage')
      .then(response => {
        setPresentation(response.data.latest_q_webcast)
        setReport(response.data.latest_annual_report)
      })
  }, [])


  useEffect(() => {
    const fetchNews = async () => {
      const releasesThumbnail = await axios.get(
        '/cision/papi/NewsFeed?format=json&pageSize=3'
      )
      setReleases(releasesThumbnail.data.Releases)
    }

    fetchNews()
  }, [])


  const playYTB = (url) => {
    let regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    let r = url.match(regex);
    setShowVideo(true);
    setType('youtube');
    setId(r[1])
  }

  return (
    <>
      <GlobalStyle></GlobalStyle>
      <SEO
        title="Checkin.com Group AB - News & Investor Relations"
        description="We build unique software using advanced technologies and an innovative approach to make it easier for consumers to connect with brands and services online."
        canonical="/"
        ogImage="/images/og.jpg"
      />

      <PageContainer>
        <IndexIllustrationContainer>
          <IndexIllustration />
        </IndexIllustrationContainer>
        <Section style={{ marginBottom: '60px' }}>
          <Sectionheader>
            <H1>Reshaping how consumers checkin
              <br /> with products and brands online</H1>
            <SectionDescription>
              Our advanced technologies and innovations offers a
              <br /> unique end to end solution that reshapes how consumers
              <br /> all over the world checkin with products and brands online.
            </SectionDescription>
          </Sectionheader>
          <NumbersContainer>
            <H2>Checkin.com Group in numbers</H2>
            <Numbers>
              <Number>
                <Code />
                <p>Rows of code</p>
              </Number>
              <Number>
                <Software />
                <p>Software releases</p>
              </Number>
              <Number>
                <DataPoints />
                <p>Data points</p>
              </Number>
              <Number>
                <Slack />
                <p>Slack messages</p>
              </Number>
              <Number>
                <Email />
                <p>Internal emails</p>
              </Number>
            </Numbers>
          </NumbersContainer>
          {showVideo && (
            <Video id={id} type={type} onClose={() => setShowVideo(false)} />
          )}

        </Section>
        <Section>

          <VideosContainer>

            <FinancialTarget className="full-width-div">
              <Content>
                <h3>Financial target</h3>
                <p className="description" >Maximise the combined measurement of Revenue Growth per Share and EBITDA Margin, with the ambition that the measurement shall exceed >80% on a yearly basis.</p>
                <p className="subHead"><span>•</span>Revenue Growth per Share</p>
                <p>Net revenue divided by the average number of outstanding shares during the period, compared to the same period the previous year.</p>
                <p className="subHead ebitda"> <span>•</span>EBITDA Margin</p>
                <p>Earnings before interest, taxes, depreciation and amortization as a percentage of net revenues.  </p>
              </Content>
              <Rocket></Rocket>
            </FinancialTarget>
            <LastWebcast className="next-row-div" >
              <PlayButton onClick={() => playYTB(presentation)} />
            </LastWebcast >
            <LastReport className="next-row-div">
              <DownloadButton onClick={() => window.open(report, '_blank')} > <span>Download</span><Download /> <span></span> </DownloadButton>
            </LastReport>
            <CompanyPresentation className="next-row-div">
              <PlayButton onClick={() => { setShowVideo(true); setType('vimeo'); setId(649525389) }} />
            </CompanyPresentation>
          </VideosContainer>
          <NavigationBlocksContainer>
            <NavigationBlock background={"#FFF9F2"}>
              <h4>Media <br /> relations</h4>
              <NavigationBlockContent >
                {releases.map((release, index) => (
                  <div key={index}>
                    <JobHeader>
                      {format(
                        new Date(release.PublishDate),
                        "yyyy-MM-dd HH:mm"
                      )}{" "}
                    </JobHeader>
                    <JobName
                      to={`/newsroom/${slugify(release.Title + release.PublishDate, { replacement: '_', remove: /[*+~.()'"!:@]/g })}/`}
                      state={{
                        modal: true,
                      }}
                    >
                      {release.Title}
                    </JobName>
                  </div>
                ))}
              </NavigationBlockContent>
              <NavigationBlockButton background={"#FBD65D"} to={"/newsroom/"}>
                News & PR →
              </NavigationBlockButton>
            </NavigationBlock>
            <NavigationBlock background={"#EBF6FA"}>
              <h4>Investor relations</h4>
              <NavigationBlockContent>
                <NavigationBlockLink to={"/investors/reports/"}>
                  → Reports and Webcasts
                </NavigationBlockLink>
                <NavigationBlockLink to={"/investors/press/"}>
                  → Press releases
                </NavigationBlockLink>
                <NavigationBlockLink to={"/investors/ipo/"}>
                  → IPO 2021
                </NavigationBlockLink>
                <NavigationBlockLink to={"/investors/calendar/"}>
                  → Calendar
                </NavigationBlockLink>
                <NavigationBlockLink to={"/investors/governance/"}>
                  → Governance
                </NavigationBlockLink>
              </NavigationBlockContent>
              <NavigationBlockButton background={"#4BC1FF"} to={"/investors"}>
                Investor relations →
              </NavigationBlockButton>
            </NavigationBlock>
            <NavigationBlock background={"#F4E9F7"}>
              <h4>Career opportunities</h4>
              <NavigationBlockContent>
                {jobs.map((job, index) => (
                  <div key={index}>
                    <JobHeader>
                      <span>{job.tags[1].name}</span>
                    </JobHeader>
                    <JobName
                      to={`/careers/${job.slug}`}
                      state={{
                        modal: true,
                      }}
                    >
                      {job.title}
                    </JobName>
                  </div>
                ))}
              </NavigationBlockContent>
              <NavigationBlockButton background={"#C894D6"} to={"/careers"}>
                Open positions →
              </NavigationBlockButton>
            </NavigationBlock>
          </NavigationBlocksContainer>
        </Section>
        <Section>
          <Sectionheader>
            <H2>
              What we do
            </H2>
            <SectionDescription>
              Checkin.com Group is changing how end users checkin with products
              and
              <br /> brands online, this means that we help them sign up,
              register and log in with ease.
            </SectionDescription>
          </Sectionheader>
          <IllustratedFeaturesContainer>
            <IllustratedFeature
              image={Global}
              svg={true}
              header={"Reach globally"}
              text={
                "Our global software solutions are used by leading brands to improve the checkin experience for millions of consumers from nearly every country in the world."
              }
            />
            <IllustratedFeature
              image={Conversion}
              svg={true}
              header={"Grow efficiently"}
              text={
                "We create shareholder value through capital efficient growth achieved by strong organic growth YoY and strategic acquisitions."
              }
            />
            <IllustratedFeature
              image={Lizzard}
              svg={true}
              header={"Attract top talent"}
              text={
                "To access the world’s top talents we are a remote company with international teams that enjoy collaborating efficiently across continents and time zones."
              }
            />
            <IllustratedFeature
              image={Rabbit}
              svg={true}
              header={"Build incredible software"}
              text={
                "We offer the world’s only end to end solution that improves every aspect of how consumers checkin with products and brands online."
              }
            />
            <IllustratedFeature
              image={DataDriven}
              svg={true}
              header={"Profitable business"}
              text={
                "Our business model enables high margins and a strong cash flow while revenue scales organically with the growing success of the company’s customers."
              }
            />
            <IllustratedFeature
              image={Star}
              svg={true}
              header={"Acquire advanced tech"}
              text={
                "We identifiy and acquire advanced technologies and strong teams within hyper specialized areas that strengthen the Group’s unique end to end SaaS-software."
              }
            />
          </IllustratedFeaturesContainer>
        </Section>
      </PageContainer >
    </>
  )
}

export const pageQuery = graphql`
  query {

    strapiData: strapiReportspage {
      title
      latest_annual_report
      latest_q_webcast
      reportgroups {
        year
        reports {
          name
          presentation
          report
        }
      }
    }
    adopted: file(relativePath: { eq: "product/adopted.png" }) {
      desktop: childImageSharp {
        fixed(height: 67, width: 67) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    tested: file(relativePath: { eq: "product/tested.png" }) {
      desktop: childImageSharp {
        fixed(height: 62, width: 86) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    conversion: file(relativePath: { eq: "product/conversion-increase.png" }) {
      desktop: childImageSharp {
        fixed(height: 77, width: 70) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    trusted: file(relativePath: { eq: "product/trusted.png" }) {
      desktop: childImageSharp {
        fixed(height: 75, width: 69) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    localized: file(relativePath: { eq: "product/localized.png" }) {
      desktop: childImageSharp {
        fixed(height: 58, width: 68) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    whitelabeled: file(relativePath: { eq: "product/whitelabeled.png" }) {
      desktop: childImageSharp {
        fixed(height: 64, width: 64) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`
