import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useSelector } from "react-redux"

const NavLink = styled(Link)`
  padding: 0 10px;
  display: flex;
  position: relative;
  height: 40px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px dashed transparent;
  font-size: 13px;
  transition: 0.4s border;
  margin-left: 5px;
  font-weight: 700;

  span {
    height: 13px;
  }

  &.active,
  &:hover {
    border: 1px dashed #000;
  }

  &.unhoverable {
    border: none;
  }

  &.hide {
    display: none;
  }

  @media screen and (max-width: 640px) {
    text-align: center;
  }
`

const Container = styled.div`
  display: flex;
  width: 809px;
  border-top: ${props => (props.border ? '1px solid #979797' : "none")};
  justify-content: center;
  padding: 21px 64px 0;
  box-sizing: border-box;
  margin-top: -50px;
  margin-bottom: 50px;

  @media screen and (max-width: 980px) {
    display: ${props => (props.showOnMobile ? "flex" : "none")};
    width: 80%;
  }

  @media screen and (max-width: 640px) {
    padding: 21px 0px 0;
    width: 100%;
  }
`

const defaultLinks = [
  {
    to: "/investors/reports/",
    text: "Reports and Webcasts",
  },
  {
    to: "/investors/press/",
    text: "Press releases",
  },
  {
    to: "/investors/ipo/",
    text: "IPO 2021",
  },
  {
    to: "/investors/calendar/",
    text: "Calendar",
  },
  {
    to: "/investors/governance/",
    text: "Governance",
  },
  {
    to: "/investors/share/",
    text: "The Share",
  },
]

export default function InvestorsNavbar({
  border = true,
  links = [...defaultLinks],
  showOnMobile = false,
}) {
  const isMobile = useSelector(state => state.global.isMobile)
  return (
    <Container border={border} showOnMobile={showOnMobile}>
      {links.map(({ to, text, flag }) => (
        <NavLink key={text} to={to} activeClassName="active" hrefLang="en">
          {flag ? isMobile ? flag + ' ' + text : text + ' ' + flag : text}
        </NavLink>
      ))}
    </Container>
  )
}
