import React, { useEffect, useState, useRef } from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { rgba } from "polished"
import Cookies from "js-cookie"

import {
  detectDevice,
  setWindowHeight,
  trackBehaviorInfo,
} from "../state/actions"
import GlobalStyle from "./global-style"
import NavBar from "./nav-bar"
import FullScreenNav from "./full-screen-nav"
import DashboardLayout from "./dashboard-layout"
import RemoteToolLayout from "./remote-tool-layout"
import getUrlParam from "../utils/get-url-param"
import newsTemplates from "../news-templates.json"
import Footer from "../components/footer"
import GetStarted from "../components/get-started"
import CalendarLayout from "./calendar-layout"
import IntegrationLayout from "./integration-layout"

import GlobalContext from "../context/global-context"

const LayoutContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  flex: 1;
  background: ${props => props.color};

  &::after {
    content: "";
    position: fixed;
    height: 300px;
    width: 100%;
    top: 0px;
    background: ${props => props.color};
    z-index: -1;
  }
`

const Tag = styled.div`
  font-size: 10px;
  font-weight: 700;
  background: #769fed;
  text-transform: uppercase;
  color: #fff;
  padding: 3px 6px;
  margin-right: 10px;
`

const NewsBox = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: #fff;
  border-top: 1px dashed #000;
  z-index: 3000;
  box-sizing: border-box;
  transform: translate3d(0, 0%, 0);
  transition: transform 1s ease;

  @media screen and (min-width: 641px) {
    display: none;
  }

  p {
    font-size: 13px;
  }

  ${props =>
    props.hide &&
    css`
      transform: translate3d(0, 100%, 0);
    `}
`

const Layout = ({ children, location, pageContext, ...restProps }) => {
  const dispatch = useDispatch()
  const isMobile = useSelector(state => state.global.isMobile)
  const windowHeight = useSelector(state => state.global.windowHeight)
  const [marketingParams, setMarketingParams] = useState({})
  const [featuredArticleAvailable, setFeaturedArticleAvailable] = useState(
    false
  )
  const [hideNewsBox, setHideNewsBox] = useState(false)
  const getStartedRef = useRef()
  const [chatOpen, setChatOpen] = useState(false)
  const [showIPOBanner, setShowIPOBanner] = useState(false)

  const toggleChat = () => {
    setChatOpen(v => !v)
  }

  const openGetStarted = (...args) => {
    getStartedRef.current && getStartedRef.current.open(...args)
  }

  const toggleIPOBanner = (hide = false) => {
    if (hide) {
      Cookies.set("hideIPOBanner", true, { expires: 7 })
      setShowIPOBanner(false)
      return
    }
    const cookie = Cookies.get("hideIPOBanner")
    if (!cookie) setShowIPOBanner(true)
  }

  let script = useRef()

  useEffect(() => {
    if (typeof window === "undefined") return

    const excludedPaths = [
      `/scanning/`,
      `/guide/`,
      `/get-started/`,
      `/publishing/`,
      `/dashboard/`,
      `/remote/tool/`,
      `/blog/`,
      `/wp-download/`,
      `/auth/`,
      `/backoffice/`,
      `/guide/wp-download/`,
      `/preview/`,
      `/start-2/`,
      `/meeting-scheduled/`,
      `/r/`,
      `/integration/`,
      `/calendar/`,
    ]

    const addHubspot = d => {
      if (script.current) return
      script.current = d.createElement("script")
      script.current.type = "text/javascript"
      script.current.async = true
      script.current.onload = () => { }
      script.current.src = "//js.hs-scripts.com/5855020.js"
      d.getElementsByTagName("head")[0].appendChild(script.current)
    }

    const removeHubspot = d => {
      if (!script.current) return
      let head = d.getElementsByTagName("head")[0]
      head.removeChild(script.current)
      script.current = null
      let remainingScript = d.getElementById("hs-script-loader")
      remainingScript && head.removeChild(remainingScript)
    }

    if (excludedPaths.includes(location.pathname)) removeHubspot(document)
    else addHubspot(document)
  }, [location])

  useEffect(() => {
    if (typeof window === "undefined") return
    dispatch(detectDevice())
    // dispatch(trackCompanyInfo())
    dispatch(trackBehaviorInfo({}, true))
  }, [dispatch])

  useEffect(() => {
    const [int, kw, cmp] = [
      getUrlParam("int"),
      getUrlParam("kw"),
      getUrlParam("cmp"),
    ]
    setFeaturedArticleAvailable(!!int && !!kw && !!cmp)
    setMarketingParams({
      int,
      kw,
      cmp,
    })

    setTimeout(() => {
      setHideNewsBox(true)
    }, 15000)
  }, [])

  useEffect(() => {
    if (typeof window === "undefined") return
    dispatch(setWindowHeight(window.innerHeight))

    window.onresize = () => {
      if (Math.abs(windowHeight - window.innerHeight) < 200) {
        dispatch(setWindowHeight(window.innerHeight))
      }
    }
  }, [windowHeight, dispatch])

  useEffect(() => {
    toggleIPOBanner()
  }, [])

  if (pageContext.layout === "dashboard")
    return (
      <DashboardLayout
        location={location}
        pageContext={pageContext}
        {...restProps}
      >
        <GlobalStyle />
        {children}
      </DashboardLayout>
    )

  if (pageContext.layout === "remote-tool")
    return (
      <RemoteToolLayout
        location={location}
        pageContext={pageContext}
        {...restProps}
      >
        <GlobalStyle />
        {children}
      </RemoteToolLayout>
    )

  if (pageContext.layout === "calendar")
    return (
      <CalendarLayout
        location={location}
        pageContext={pageContext}
        {...restProps}
      >
        <GlobalStyle />
        {children}
      </CalendarLayout>
    )

  if (pageContext.layout === "integration")
    return (
      <IntegrationLayout
        location={location}
        pageContext={pageContext}
        {...restProps}
      >
        <GlobalStyle />
        {children}
      </IntegrationLayout>
    )

  if (pageContext.layout === "files")
    return (
      <>
        <GlobalStyle />
        {children}
      </>
    )

  const getBackgroundColor = context => {
    const map = {
      layout: [
        { route: "/", color: "transparent" },
        { route: "scanning", color: "#F9F9F8" },
        { route: "guide", color: "#F9F9F8" },
        { route: "publishing", color: "#F9F9F8" },
        { route: "beta", color: "#F9F9F8" },
      ],
      navbar: [
        { route: "/", color: rgba("#f9f9f8", 0.98) },
        { route: "scanning", color: "transparent" },
        { route: "about-us", color: rgba("#f9f9f8", 0.98) },
        { route: "checkin-software", color: rgba("#f9f9f8", 0.98) },
      ],
    }

    const result = map[context].find(
      obj => obj.route.indexOf(location.pathname.split("/")[1]) !== -1
    )
    return (result && result.color) || "#f9f9f8"
  }

  const showFooter = () =>
    ["investors", "newsroom", "careers"].find(v =>
      location.pathname.includes(v)
    )

  return (
    <>
      <GlobalContext.Provider
        value={{
          openGetStarted,
          chatOpen,
          toggleChat,
          showIPOBanner,
          toggleIPOBanner,
        }}
      >
        <GetStarted ref={getStartedRef} />
        <LayoutContainer
          color={getBackgroundColor("layout")}
          style={
            isMobile
              ? {}
              : {
                minHeight: windowHeight,
                paddingTop:
                  showIPOBanner && location.pathname !== "/investors/ipo/"
                    ? "70px"
                    : 0,
              }
          }
        >
          <GlobalStyle />
          <NavBar
            location={location.pathname}
            color={getBackgroundColor("navbar")}
          />
          {children}
          {!location.pathname.includes("/get-started") &&
            !location.pathname.includes("/meeting-booker") && (
              <FullScreenNav
                location={location.pathname}
                hideOnFullScroll={
                  location.pathname !== "/" || location.pathname !== "/start-2"
                }
              />
            )}

          {(location.pathname === "/" || location.pathname === "/start-2") &&
            featuredArticleAvailable && (
              <NewsBox hide={hideNewsBox}>
                <Tag>News</Tag>
                <p>
                  {newsTemplates.templates[marketingParams.int].headline
                    .replace("[kw]", newsTemplates.kw[marketingParams.kw])
                    .replace("[cmp]", newsTemplates.cmp[marketingParams.cmp])}
                </p>
              </NewsBox>
            )}
          {(location.pathname === "/" || showFooter()) && (
            <Footer location={location} />
          )}
        </LayoutContainer>
      </GlobalContext.Provider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
