import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { format } from "date-fns"

import Tag from "./tag"
import arrowBack from "../../assets/images/arrow-back.svg"
const slugify = require("slugify")

const Arrow = styled.div`
  width: 27px;
  height: 18px;
  background: url(${arrowBack}) no-repeat center;
  position: absolute;
  bottom: 20px;
  right: 30px;
  transform: rotate(180deg);
  opacity: 0;
  transition: opacity 0.15s ease;
  @media screen and (max-width: 640px) {
    right: 20px;
  }
  ${(props) =>
    props.previous &&
    css`
      right: auto;
      left: 30px;
      transform: none;
      @media screen and (max-width: 640px) {
        left: 20px;
      }
    `}
`

const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #fff;
  border: 1px solid #000000;
  position: relative;
  align-items: flex-start;
  margin-bottom: 42px;
  transition: all 0.15s ease;
  @media screen and (min-width: 981px) {
    &:hover {
      margin-left: -6px;
      margin-right: -6px;
      margin-top: -6px;
      margin-bottom: 36px;
      padding: 26px 26px 56px;
      transition: all 0.3s ease;
      ${Arrow} {
        opacity: 1;
        transition: all 0.3s ease;
      }
    }
  }
  &::after {
    content: "";
    position: absolute;
    border: 1px solid #000000;
    border-left: none;
    transform: skewY(-30deg);
    transform-origin: left bottom;
    height: calc(100% + 2px);
    width: 14px;
    top: -1px;
    left: calc(100% + 1px);
    pointer-events: none;
    background: ${(props) => props.color};
    box-sizing: border-box;
  }
  &::before {
    content: "";
    position: absolute;
    border: 1px solid #000000;
    border-left: 2px solid #000;
    border-bottom: none;
    transform: skew(-60deg);
    transform-origin: right bottom;
    height: 8px;
    width: calc(100% + 2px);
    bottom: calc(100% + 1px);
    left: -1px;
    pointer-events: none;
    background: ${(props) => props.color};
    box-sizing: border-box;
  }
  @media screen and (max-width: 980px) {
    padding: 20px 25px;
    align-self: stretch;
    ${(props) =>
    props.minimized &&
    css`
        ${Arrow} {
          opacity: 1;
        }
      `}
  }
  @media screen and (max-width: 640px) {
    padding: 20px;
  }
  @media screen and (max-width: 375px) {
    padding: 10px;
  }
`

const Title = styled.h3`
  display: block !important;
  font-size: 20px;
  line-height: 28px;
  margin: 12px 0 8px;
  ${(props) =>
    props.minimized &&
    css`
      margin-bottom: 0px;
      @media screen and (max-width: 980px) {
        margin-bottom: 30px;
      }
    `}
`

const DateTag = styled.p`
  font-size: 16px;
  color: #7d7d7d;
  margin-top: 16px;
`

const Excerpt = styled.p`
  margin-top: 12px;
  font-size: 16px;
  line-height: 24px;

  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
`

const PostCard = ({
  post,
  minimized = false,
  previous = false,
  replace = false,
  className,
  fromApi = false,
}) => {
  const date = new Date(post.PublishDate)

  return (
    <Container
      className={className}
      color={post.IsRegulatory ? "#4EA765" : "#4BC1FF"}
      to={fromApi ? "/newsroom/post/" : `/newsroom/${slugify(post.Title + post.PublishDate, { replacement: '_', remove: /[*+~.()'"!:@]/g })}/`}
      replace={replace}
      state={{
        modal: true,
        post: fromApi ? post : null,
      }}
      minimized={minimized}
    >
      {post.IsRegulatory ? <Tag>regulatory</Tag> : <Tag>non-regulatory</Tag>}
      {!minimized && <DateTag>{format(date, "yyyy-MM-dd HH:mm")}</DateTag>}
      <Title minimized={minimized}>{post.Title}</Title>
      {!minimized && <Excerpt>{post.Intro}</Excerpt>}
      <Arrow previous={previous} />
    </Container>
  )
}

export default PostCard
