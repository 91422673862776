import React, { useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import InvestorsNavbar from "../../components/investors-navbar"
import pdfIcon from "../../assets/images/pdf-icon.svg"
import fileIcon from "../../assets/images/file-icon.svg"
import checkinLogo from "../../assets/images/checkin-logo.svg"
import Video from "../../components/video"
import SEO from "../../components/seo"

import {
  H2,
  Text,
  TextContainer,
  Sectionheader,
  PageContainer,
  SectionDescription,
} from "../../components/common"

const CustomPageContainer = styled(PageContainer)`
  width: 945px !important;

  @media screen and (max-width: 980px) {
    width: 614px !important;
  }

  @media screen and (max-width: 640px) {
    width: 345px !important;
  }
`

const CustomtextContainer = styled(TextContainer)`
  padding-left: 0 !important;
  align-self: flex-start;

  @media screen and (max-width: 980px) {
    width: 100% !important;

    ${H2} {
      font-size: 28px !important;
      line-height: 32.2px !important;
      text-transform: none !important;
      color: #000 !important;
    }
  }
`

const Table = styled.table`
  border-spacing: 0px;
  border-collapse: collapse;
  width: 100%;

  td {
    padding: 24px 20px 15px;
    border: 1px solid #afafaf;
    border-left: none;
    border-right: none;
    vertical-align: top;

    &.file {
      padding: 0;
      display: table-cell !important;
    }

    &:first-child {
      width: 300px;
    }

    p {
      font-size: 16px;
      line-height: 30px;
    }

    & > div {
      display: flex;
      height: 79px;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
    }

    span {
      font-size: 20px;
      font-weight: 700;
      display: block;

      @media screen and (max-width: 640px) {
        font-size: 16px;
        width: 196px;
        line-height: 24px;
      }
    }
  }

  @media screen and (max-width: 640px) {
    tr {
      td:first-child {
        display: none;
      }
    }

    p {
      font-size: 16px !important;
      margin-bottom: 8px;
    }
  }
`

const TableContainer = styled.div`
  width: 100%;
  margin-bottom: 140px;

  &:last-child {
    margin-bottom: 80px;
  }

  @media screen and (max-width: 980px) {
    width: 624px;
  }

  @media screen and (max-width: 640px) {
    width: 333px;
  }
`

const FileButton = styled.button`
  width: 250px;
  height: 100%;
  box-shadow: none;
  border: none;
  background: url(${fileIcon}) no-repeat center;
  outline: none;
  cursor: pointer;

  @media screen and (max-width: 980px) {
    width: 50px;
  }
`

const PDFButton = styled(FileButton)`
  background: url(${pdfIcon}) no-repeat center;
`

const VideoPlaceholder = styled.div`
  width: 580px;
  height: 350px;
  border: 1px solid #000;
  background: url(${checkinLogo}) no-repeat center/80%, #f2f2f2;
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.1);
  margin: 50px 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 640px) {
    width: 320px;
    height: 200px;
  }
`

const PlayButton = styled.button`
  background: #fff;
  outline: none;
  box-shadow: none;
  width: 70px;
  height: 70px;
  border: 1px solid #000;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:after {
    content: "";
    height: 0;
    width: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 20px solid #000;
    position: absolute;
    margin-left: 6px;
  }
`

const DisclaimerContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f8f8f7;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Disclaimer = styled.div`
  width: 946px;
  height: 861px;
  background: #fff;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 40px;
  box-sizing: border-box;

  ${H2} {
    margin-bottom: 34px;
  }

  @media screen and (max-width: 980px) {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 640px) {
    padding: 30px;
  }
`

const DisclaimerText = styled(TextContainer)`
  flex: 1;
  padding: 0;
  margin: 0;
  padding: 0 145px;
  box-sizing: border-box;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e2e3e5;
  }

  @media screen and (max-width: 980px) {
    padding: 0 90px;
  }

  @media screen and (max-width: 640px) {
    padding: 0 10px;
  }
`

const DisclaimerButtonsContainer = styled.div`
  display: flex;
  margin-top: 70px;

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }

  @media screen and (max-width: 640px) {
    margin-top: 20px;
  }
`

const DisclaimerButton = styled.button`
  width: 215px;
  height: 48px;
  box-sizing: border-box;
  border: 1px solid #000;
  outline: none;
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.1);
  margin: 0 22px;
  cursor: pointer;
  background: #fff;
  font-size: 15px;
  font-weight: 700;

  &:hover {
    box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 1);
  }

  @media screen and (max-width: 640px) {
    margin: 10px 0;
  }
`

const MobileHeader = styled.span`
  display: none !important;
  font-size: 20px;
  font-weight: 700;
  display: block;

  @media screen and (max-width: 640px) {
    font-size: 16px;
    width: 100% !important;
    line-height: 24px;
    display: block !important;
  }
`

export default function IPOPage() {
  const [showVideo, setShowVideo] = useState(false)
  const [termsAccepted, setTemrsAccepted] = useState(false)

  return (
    <>
      <SEO
        title="Checkin.com Group AB - IPO 2021 prospect"
        description="Read the prospectus for the 2021 IPO (Swedish)."
        canonical="/investors/ipo"
        ogImage="/images/og.jpg"
      />
      {!termsAccepted && (
        <>
          <CustomPageContainer>
            <DisclaimerContainer>
              <Disclaimer>
                <H2>Important notice</H2>
                <DisclaimerText>
                  <Text>
                    You are about to view a web page with information that is
                    not intended for release, publication or distribution,
                    directly or indirectly, in or into the United States of
                    America, Canada, Australia, New Zealand, Hong Kong, Japan,
                    Singapore or South Africa. By clicking "Yes, I accept" you
                    accept the terms of use below.
                  </Text>
                  <Text>
                    The offer to subscribe for shares in Checkin.com Group AB
                    (publ) according to the terms set out in the prospectus is
                    not directed to people whose participation in the offer
                    requires additional prospectuses, registration or measures
                    other than those pursuant to Swedish law. The prospectus and
                    other documents connected to the prospectus may not be
                    distributed in any country in which the distribution or the
                    offer requires measures as outlined above or are contrary to
                    the regulations in such countries. The shares encompassed by
                    the offer according to the prospectus have not been
                    registered and will not be registered in accordance with the
                    United States Securities Act from 1933 in its current
                    wording or any provincial legislation in Canada, Australia,
                    New Zealand, Hong Kong, Japan, Singapore or South Africa and
                    may not be offered or sold, directly or indirectly, to
                    persons domiciled in any of these countries or for the
                    account of such a person. Application to subscribe for
                    shares contrary to the above may be viewed as invalid and
                    consequently ignored.
                  </Text>
                  <Text>
                    Disputes arising from the offer, the contents of the
                    prospectus and thereto connected legal matters shall be
                    determined exclusively by a Swedish Court. Swedish law is
                    exclusively applicable on the prospectus and the offer in
                    accordance to the prospectus.
                  </Text>
                  <Text>
                    In order to be eligible to view the information on the
                    following web page, you shall be deemed, by accessing the
                    following web page, to have confirmed and represented that
                    (i) you have read and accepted the terms and restrictions
                    set out above, and (ii) you are not located in the United
                    States of America, Canada, Australia, New Zealand, Hong
                    Kong, Japan, Singapore or South Africa.
                  </Text>
                </DisclaimerText>
                <DisclaimerButtonsContainer>
                  <DisclaimerButton onClick={() => navigate("/")}>
                    Decline
                  </DisclaimerButton>
                  <DisclaimerButton
                    style={{ background: "#FFD63E" }}
                    onClick={() => setTemrsAccepted(true)}
                  >
                    Yes, I accept
                  </DisclaimerButton>
                </DisclaimerButtonsContainer>
              </Disclaimer>
            </DisclaimerContainer>
          </CustomPageContainer>
        </>
      )}

      {termsAccepted && (
        <>
          {showVideo && (
            <Video id="544464652" onClose={() => setShowVideo(false)} />
          )}

          <CustomPageContainer>
            <InvestorsNavbar />
            <Sectionheader>
              <H2>IPO 2021</H2>
              <SectionDescription>
                Du har nu möjlighet att anmäla teckning av aktier i Checkin.com
                Group <br />
                inför bolagets notering på Nasdaq First North Growth Market
              </SectionDescription>
            </Sectionheader>

            <VideoPlaceholder>
              <PlayButton onClick={() => setShowVideo(true)} />
            </VideoPlaceholder>

            <CustomtextContainer>
              <H2>Om bolaget</H2>
              <Text>
                Checkin.com grundades 2017 och är ett svenskt SaaS-bolag som
                erbjuder företagskunder en mjukvaruplattform som förbättrar hur
                slutanvändare registrerar sig, identifierar sig och loggar in
                (benämns tillsammans som ”checkin”). Genom mjukvaruplattformen
                erhåller bolagets kunder en komplett checkin-lösning som bidrar
                till en ökad konverteringsgrad, från potentiella till
                registrerade slutanvändare. Under 2020 hanterade bolagets
                mjukvaruplattform över 18 miljoner checkin-sessioner i över 165
                länder. Checkin.com har huvudkontor i Stockholm och 37
                medarbetare, utspridda i totalt tolv länder.
              </Text>
              <Text>
                Bolagets teknologi kombinerar flera UX-system (eng. user
                experience), datakällor och identifieringsmetoder för att skapa
                individuellt anpassade checkin-flöden för varje slutanvändare.
                Bolagets mjukvara ökar konverteringsgraden samtidigt som
                nödvändig identifiering och regelefterlevnad säkerställs.
                Bolagets potentiella marknad omfattar alla företag som skapar
                varaktiga kundrelationer på nätet.
              </Text>
              <Text>
                Checkin.com har kontinuerligt sedan grundandet 2017 uppvisat en
                stark tillväxt och nådde för helåret 2020 en nettoomsättning på
                22,3 MSEK med accelererande tillväxt under det andra halvåret.
                EBITDA uppgick till 1,7 MSEK under räkenskapsåret 2020. Per den
                31 mars 2021 uppgick bolagets nettoomsättning för de tolv
                föregående månaderna till 25,3 MSEK.
              </Text>
              <Text>
                Bolagets affärsmodell är prenumerationsbaserad och Checkin.com
                erbjuder olika månadspaket till fasta priser, baserat på storlek
                och kringtjänster. Bolagets nettoomsättning är av återkommande
                karaktär och inga integrationsavgifter eller andra avgifter av
                engångskaraktär tas ut.
              </Text>
              <Text>
                Checkin.com riktar sig i dagsläget framförallt till tre
                kundvertikaler, finansiell teknologi (fintech), iGaming och
                prenumerationstjänster, varav iGaming för tillfället utgör den
                största kundvertikalen och stod för en majoritet av omsättningen
                under 2020. Marknaderna inom respektive kund-vertikal är under
                stark tillväxt, vilket innebär att Checkin.com ser stora
                möjligheter till att växa tillsammans med kunderna. Det är
                bolagets bedömning att den globala marknaden för checkin är
                stor, fragmenterad och omättad och bolaget ser stora möjligheter
                till att växa i ytterligare kundvertikaler.
              </Text>
              <Text>
                Enligt bolagets övergripande strategi förväntas tillväxten ske
                genom investeringar i tre huvudsakliga områden, där
                nykundstillväxt och utveckling av existerande kunder
                kompletteras med en mer aktiv förvärvsstrategi i samband med
                noteringen.
              </Text>
              <Text>
                Cornerstone Investors vid noteringen är TIN Fonder, Knutsson
                Holdings, Norron Select och Erik Selin genom ES Aktiehandel AB.
                De har åtagit sig att, under vissa villkor, och till samma pris
                som övriga investerare, teckna totalt 1 741 935 aktier,
                motsvarande cirka 60,0 procent av Erbjudandet till ett totalt
                värde om cirka 27,0 MSEK. Sedan tidigare har finansprofiler som
                Nicklas Storåkers och Eirik Winter investerat i bolaget.
                Tidigare finansminister Anders Borg är bolagets
                styrelseordförande.
              </Text>
              <Text>
                Mer information om Checkin.com Group finner du i{" "}
                <a target="__blank" href="/documents/prospect.pdf">
                  prospektet
                </a>
                .
              </Text>
            </CustomtextContainer>
            <TableContainer>
              <H2>Fakta om noteringen</H2>
              <Table>
                <tbody>
                  <tr>
                    <td>
                      <span>Pris</span>
                    </td>
                    <td>
                      <MobileHeader>Pris</MobileHeader>
                      <p>15.50 SEK per aktie</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Emissionsvolym</span>
                    </td>
                    <td>
                      <MobileHeader>Emissionsvolym</MobileHeader>
                      <p>
                        Emissionen består av 2 903 226 stamaktier vilket
                        motsvarar ett emissionsbelopp om cirka 45,0 MSEK
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Bolagsvärde</span>
                    </td>
                    <td>
                      <MobileHeader>Bolagsvärde</MobileHeader>
                      <p>Cirka 359,3 MSEK före Erbjudandet</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Ankarinvesterare</span>
                    </td>
                    <td>
                      <MobileHeader>Ankarinvesterare</MobileHeader>
                      <p>
                        De befintliga aktieägarna Tin Fonder, Knutsson Holdings,
                        Norron Select och Erik Selin genom ES Aktiehandel AB är
                        ankarinvesterare i noteringsemissionen.
                        Teckningsåtagandet för ankarinvesterarna motsvarar cirka
                        27,0 MSEK eller 60 procent av Erbjudandet.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Lock up</span>
                    </td>
                    <td>
                      <MobileHeader>Lock up</MobileHeader>
                      <p>
                        Samtliga befintliga aktieägare i Checkin.com, med
                        undantag för ankarinvesterarna, har ingått s.k lock-up
                        avtal. Totalt omfattar ingångna lock-up avtal cirka 83%
                        av bolagets aktier efter Erbjudandets genomförande givet
                        en fulltecknad emission.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Teckningsperiod</span>
                    </td>
                    <td>
                      <MobileHeader>Teckningsperiod</MobileHeader>
                      <p>2021-05-04 till 2021-05-12</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Offentliggörande av utfall</span>
                    </td>
                    <td>
                      <MobileHeader>Offentliggörande av utfall</MobileHeader>
                      <p>Runt 2021-05-14</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Likviddag</span>
                    </td>
                    <td>
                      <MobileHeader>Likviddag</MobileHeader>
                      <p>2021-05-18</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Minsta teckning</span>
                    </td>
                    <td>
                      <MobileHeader>Minsta teckning</MobileHeader>
                      <p>400 aktier, motsvarande 6 200 SEK</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Marknadsplats</span>
                    </td>
                    <td>
                      <MobileHeader>Marknadsplats</MobileHeader>
                      <p>Nasdaq First North Growth Market.</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>ISIN kod & Ticker</span>
                    </td>
                    <td>
                      <MobileHeader>ISIN kod & Ticker</MobileHeader>
                      <p>SE0015810502 CHECK</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Första dag för handel</span>
                    </td>
                    <td>
                      <MobileHeader>Första dag för handel</MobileHeader>
                      <p>2021-05-20</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Bolagets hemsida</span>
                    </td>
                    <td>
                      <MobileHeader>Bolagets hemsida</MobileHeader>
                      <p>https://group.checkin.com</p>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </TableContainer>

            <TableContainer>
              <H2>Filer</H2>
              <Table>
                <tbody>
                  <tr>
                    <td className="file">
                      <div>
                        <span>Prospekt (Svenska).pdf</span>
                        <PDFButton
                          onClick={() =>
                            window.open("/documents/prospect.pdf", "_blank")
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="file">
                      <div>
                        <span>Anmälningssedel (Svenska).pdf</span>
                        <PDFButton
                          onClick={() =>
                            window.open(
                              "/documents/Anmälningssedel-swe.pdf",
                              "_blank"
                            )
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="file">
                      <div>
                        <span>Anmälningssedel (Engelska).pdf</span>
                        <PDFButton
                          onClick={() =>
                            window.open(
                              "/documents/Anmälningssedel-eng.pdf",
                              "_blank"
                            )
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="file">
                      <div>
                        <span>Tilläggsdokument (Svenska).pdf</span>
                        <PDFButton
                          onClick={() =>
                            window.open(
                              "/documents/Checkin.com_Group_559096-3087_Tilläggsdokument.pdf",
                              "_blank"
                            )
                          }
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </TableContainer>
          </CustomPageContainer>
        </>
      )}
    </>
  )
}
