import React, { useContext } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

import LogoFooter from "../assets/images/regily-white-logo.svg"

import GlobalContext from "../context/global-context"

const Logo = styled(Link)`
  width: 212px;
  height: 46px;
  text-indent: -9999px;
  background: url(${LogoFooter}) no-repeat center/contain;
`

const Container = styled.div`
  background: #000;
  grid-area: footer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  padding: 25px 30px;
  z-index: 2100;

  ${props =>
    props.chatOpen &&
    css`
      z-index: initial;
    `}

  @media screen and (max-width: 980px) {
    flex-direction: column;
    padding: 20px 0;
    height: 148px;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1380px;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

const FooterBottom = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
`

const Address = styled.p`
  font-size: 12px;
  color: #bdbcc1;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: #fff;
    margin-left: 4px;
    color: #bdbcc1;

    &:hover {
      color: #fff;
    }
  }
`

const EmailLink = styled.a`
  color: #fff;
  font-size: 12px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Footer = ({ className, location }) => {
  const globalContext = useContext(GlobalContext)

  return (
    <Container className={className} chatOpen={globalContext.chatOpen}>
      <Wrapper>
        <FooterBottom>
          <LogoContainer>
            <Logo to="/" />
          </LogoContainer>
          <Address>
            Checkin.com Group AB, Engelbrektsplan 2, 114 34 Stockholm, Sweden
            {location.pathname.includes("about-us") && (
              <>
                {" "}
                |{" "}
                <a target="__blank" href="/files">
                  Documents
                </a>
              </>
            )}
          </Address>
          <EmailLink href="mailto:hello@regily.com">
            hello@checkin.com
          </EmailLink>
        </FooterBottom>
      </Wrapper>
    </Container>
  )
}

export default Footer
