import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Caret from "../../assets/images/caret.inline.svg"
import {
  H1,
  Section,
  Sectionheader,
  PageContainer,
} from "../../components/common"
import SEO from "../../components/seo"

const LinkBoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 42px 30px;
  margin: 60px 0;
  z-index: 1;

  @media screen and (max-width: 980px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 40px 0;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`

const LinkBox = styled(Link)`
  width: 362px;
  height: 263px;
  border: 1px solid #000;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${props => props.background || "#fff"};
  box-sizing: border-box;
  padding: 40px;

  @media screen and (max-width: 980px) {
    width: 293px;
    height: 241px;
  }

  @media screen and (max-width: 640px) {
    width: 345px;
    height: 195px;
  }

  span {
    font-size: 24px;
    font-weight: 900;
    line-height: 36px;

    &:last-child {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      display: flex;
      align-items: center;
    }
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px dashed #000;
    top: 10px;
    left: 10px;
    z-index: -1;
    box-sizing: border-box;
  }

  &:hover {
    &:after {
      background: #000;
    }
  }
`

const ContactLine = styled.p`
  font-size: 20px;
  margin-top: 35px;
  line-height: 32px;

  br {
    display: none;
  }

  a {
    background: #fbd65d;
    border: 1px solid rgba(13, 13, 13, 0.1);
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.1);
    border-radius: 19px;
    padding: 8px 12px;
    margin: 0 8px;

    &:hover {
      box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 1);
    }
  }

  @media screen and (max-width: 980px) {
    br {
      display: initial;
    }
  }

  @media screen and (max-width: 640px) {
    line-height: 46px;
  }
`

export default function InvestorsPage() {
  return (
    <>
      <SEO
        title="Checkin.com Group AB - Information for Investors"
        description="Reports, press releases, calendar, governance, information about the share, and other resources for investors of Checkin.com Group."
        canonical="/investors"
        ogImage="/images/og.jpg"
      />
      <PageContainer>
        <Section>
          <Sectionheader>
            <H1>Investor relations</H1>
            <ContactLine>For more information or inquiries, please reach out to <a href="mailto:ir@checkin.com">ir@checkin.com</a></ContactLine>
          </Sectionheader>
          <LinkBoxContainer>
            <LinkBox to={"/investors/reports/"} background={"#FFF9F2"}>
              <span>Reports and Webcasts</span>
              <span>
                See more <Caret />
              </span>
            </LinkBox>
            <LinkBox to={"/newsroom/"} background={"#B4DEEC"}>
              <span>Press releases</span>
              <span>
                See more <Caret />
              </span>
            </LinkBox>
            <LinkBox to={"/investors/calendar/"} background={"#B0F1D4"}>
              <span>Calendar</span>
              <span>
                See more <Caret />
              </span>
            </LinkBox>
            <LinkBox to={"/investors/ipo/"} background={"#ECDAF1"}>
              <span>IPO 2021</span>
              <span>
                See more <Caret />
              </span>
            </LinkBox>
            <LinkBox to={"/investors/governance/"} background={"#FEF2CD"}>
              <span>Governance</span>
              <span>
                See more <Caret />
              </span>
            </LinkBox>
            <LinkBox to={"/investors/share/"} background={"#FFE6C8"}>
              <span>The Share</span>
              <span>
                See more <Caret />
              </span>
            </LinkBox>
          </LinkBoxContainer>
        </Section>
      </PageContainer>
    </>
  )
}
