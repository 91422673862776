import React from "react"

const GlobalContext = React.createContext({
  chatOpen: false,
  showIPOBanner: false,
  openGetStarted: () => {},
  openHubspotCalendar: () => {},
  closeHubspotCalendar: () => {},
  toggleChat: () => {},
  toggleIPOBanner: () => {},
})

export default GlobalContext
