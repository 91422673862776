import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media screen and (max-width: 980px) {
    width: 298px;
  }

  @media screen and (max-width: 640px) {
    align-items: center;
    width: 315px;
  }
`

const IllustratedFeatureIcon = styled.div`
  margin-right: 25px;
  width: 100px;
  min-width: 100px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  object {
    width: 100%;
  }
  @media screen and (max-width: 980px) {
    margin-right: 0;
    justify-content: flex-start;
  }

  @media screen and (max-width: 640px) {
    justify-content: center;
  }
`

const IllustratedFeatureText = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
  }

  @media screen and (max-width: 980px) {
    align-items: flex-start;

    h4 {
      font-size: 20px;
      line-height: 30px;
    }

    p {
      line-height: 24px;
    }
  }

  @media screen and (max-width: 640px) {
    text-align: center;
    align-items: center;

    h4 {
      width: 230px;
    }
  }
`

export default function IllustratedFeature({ image = null, header, text, svg }) {
  return (
    <Container>
      {image && (
        <IllustratedFeatureIcon>
          {svg ? < object data={image} type="image/svg+xml" ></object> : <Img fixed={image} />}
        </IllustratedFeatureIcon>
      )}

      <IllustratedFeatureText>
        <h4>{header}</h4>
        <p>{text}</p>
      </IllustratedFeatureText>
    </Container>
  )
}
