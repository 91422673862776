const tags = {
  "non-regulatory": {
    name: "Non-regulatory",
    slug: "non-regulator",
    colors: {
      card: "#4BC1FF",
      tag: "#DDEFF4",
    },
  },
  regulatory: {
    name: "Regulatory",
    slug: "regulatory",
    colors: {
      card: "#4EA765",
      tag: "#C0EAD6",
    },
  },
}

export default tags
