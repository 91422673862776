import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import InvestorsNavbar from "../../components/investors-navbar"
import { H1, Sectionheader, PageContainer } from "../../components/common"

import articleIcon from "../../assets/images/article.svg"
import podcastIcon from "../../assets/images/podcast.svg"

const TableContainer = styled.div`
  width: 810px;
  margin-top: 80px;

  &:last-child {
    margin-bottom: 80px;
  }

  @media screen and (max-width: 980px) {
    width: 624px;
  }

  @media screen and (max-width: 640px) {
    width: 333px;
  }
`

const Table = styled.table`
  border-spacing: 0px;
  border-collapse: collapse;
  width: 100%;

  td {
    padding: 26px 20px;
    border: 1px solid #afafaf;
    border-left: none;
    border-right: none;
    vertical-align: middle;

    &:first-child {
      width: 100%;
    }
  }
`

const Title = styled.a`
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 10px;
  display: block;
  cursor: pointer;
`

const Meta = styled.p`
  font-size: 18px;

  span {
    margin: 0 8px;
  }

  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`

const Button = styled.a`
  outline: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: block;
`

const ArticleButton = styled(Button)`
  background: url(${articleIcon}) no-repeat center;
`

const PodcastButton = styled(Button)`
  background: url(${podcastIcon}) no-repeat center;
`

export default function MentionsPage({
  data: {
    strapiData: { title, mentions = [] },
  },
}) {
  return (
    <PageContainer>
      <InvestorsNavbar
        links={[
          {
            to: "/newsroom/",
            text: "Newsroom",
            flag: " 🇺🇸"
          },
          {
            to: "/newsroom/swedish/",
            text: "Newsroom",
            flag: " 🇸🇪"
          },
          {
            to: "/newsroom/mentions/",
            text: "Mentions",
          },
        ]}
        showOnMobile
      />

      <Sectionheader>
        <H1>{title}</H1>
      </Sectionheader>

      <TableContainer>
        <Table>
          <tbody>
            {mentions.map(({ type, outlet, headline, date, link }) => (
              <tr>
                <td>
                  <Title href={link} target="__blank">
                    {headline}
                  </Title>
                  <Meta>
                    {outlet}
                    <span>|</span>
                    {date}
                  </Meta>
                </td>
                <td>
                  {type === "ARTICLE" ? (
                    <ArticleButton href={link} target="__blank" />
                  ) : (
                    <PodcastButton href={link} target="__blank" />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </PageContainer>
  )
}

export const query = graphql`
  query {
    strapiData: strapiMentionspage {
      title
      mentions {
        date
        headline
        link
        outlet
        type
      }
    }
  }
`
