import React, { useState, useEffect, useRef } from "react"
import styled, { css, keyframes } from "styled-components"
import axios from "axios"

import InvestorsNavbar from "../../components/investors-navbar"
import PostCard from "../../components/cision/post-card"
import Topics from "../../components/cision/topics"
import SEO from "../../components/seo"
import ClipLoader from "react-spinners/ClipLoader"
import ContinueButton from "../../components/continue-button"
import isEmail from "validator/lib/isEmail"
import Sub from "../../assets/images/sub.svg"
import serviceURL from "../../utils/service-url"


import {
  H1,
  H2,
  Sectionheader,
  PageContainer,
  SectionDescription,
} from "../../components/common"

const expandAnimation = keyframes`
0%   {height: 0px; opacity: 0; margin: 0;}
50%  {height: 0px; opacity: 0 ; margin: 0;}
75%  {height: 60px; opacity: 0; margin: 0;}
100% {height: 60px; opacity: 1; margin-top: 10px;}
`;

const fadeInAnimation = keyframes`
0%   {height: 0px; opacity: 0; margin: 0;}
50%  {height: 0px; opacity: 0 ; margin: 0;}
75%  {height: 86px; opacity: 0; margin: 0;}
100% {height: 86px; opacity: 1; margin-top: 30px;}
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 650px 1fr;
  margin-top: 60px;
  width: 100%;

  @media screen and (max-width: 980px) {
    grid-template-columns: 1fr;

    & > span {
      display: none;
    }
  }
`

const PostsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 980px) {
    order: 2;
  }
`

const Loader = styled.div`
  margin-top: 20px;
`
const Search = styled.div`
  grid-area: search ;
  border: 1px solid #757575;
  box-shadow: 4px 4px 0px rgba(13, 13, 13, 0.1);
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
  input {
    border: none;
    background: none;
    width: 90%;
  }
  input:focus {
    outline: none;
  }
`

const SubscriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 70px;
  pointer-events: all;
  width: 330px;
  position: relative;
  border: 0.647px solid #0D0D0D; 
  padding: 20px;
  position: sticky;
  top: 300px;
  margin-left: 48px;
  transition: height 0.5s ease;

  @media screen and (min-width: 981px) {
    padding-bottom: 30px;
  }

  @media screen and (max-width: 980px) {
    margin-left: 0px;
    width: auto;
    margin-top: 0px;
    margin-bottom: 40px;

    img {
      top: -73px;
      right: -19px;
    }
  }

  @media screen and (max-width: 640px) {
  padding-bottom: 50px;
  }
  h3 {
    color: #0D0D0D; 
    width: 100%;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; 
  }

  input {
    width: 100%;
    border: none;
    border-bottom: 0.849px solid rgba(25, 25, 26, 0.15); 
    background: transparent;
    height: 50px;

    &::placeholder {
      color: #757575;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; 
    }
  

  }

  input[type='checkbox'] {
    width: auto;
    height: auto;
  }
  &::after {
    content: "";
    position: absolute;
    border: 1px solid #000000;
    border-left: none;
    transform: skewY(-30deg);
    transform-origin: left bottom;
    height: calc(100% + 2px);
    width: 12px;
    top: -1px;
    left: calc(100% + 1px);
    pointer-events: none;
    background: ${props => props.color};
    box-sizing: border-box;
  }

  &::before {
    content: "";
    position: absolute;
    border: 1px solid #000000;
    border-left: 2px solid #000;
    border-bottom: none;
    transform: skew(-60deg);
    transform-origin: right bottom;
    height: 6px;
    width: calc(100% + 2px);
    bottom: calc(100% + 1px);
    left: -1px;
    pointer-events: none;
    background: ${props => props.color};
    box-sizing: border-box;
  }

  img {
    position: absolute;
    top: -60px;
    right: -60px;
    width: 140px;
    z-index: 10;

    @media screen and (max-width: 980px) {
        top: -73px;
        right: -19px;
    }
  }
`

const EmailInput = styled.div`
  width: 100%;
  display: flex;
  input { outline: none; }
  input:focus { outline:none; }
  

`

const SubmitButton = styled(ContinueButton)`
  position: absolute;
  width: 50px;
  height: 50px;
  right: 10px;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.5s ease;
  background: #ffd63e;
  margin-bottom: 28px;
  top: 75px;

  @media screen and (max-width: 980px) {
  top: 45px;
  }

  @media screen and (max-width: 640px) {
    top: 75px;
    }

  ${props =>
    props.success &&
    css`
      background: #b6fad9;
    `}
`
const Options = styled.div`



display: flex;
flex-wrap: wrap;
justify-content: space-between;
width: 100%;
margin: 15px;
height: 0px;
opacity: 0;
margin: 0;
// display:none;


${(props) =>
    props.expand &&
    css`
    animation: ${fadeInAnimation} 0.5s ease-in-out;
    opacity: 1;
    height: 86px;
    display: flex;
    margin-top: 30px;
  `}

div {
  width: calc(50% - 8px); /* 50% width with some spacing */
  margin-bottom: 10px; /* Adjust spacing between rows as needed */
  box-sizing: border-box; /* Include padding and border in the width */
  display: flex;
  align-items: center;
}

input {
  margin-right: 8px; /* Adjust spacing between checkbox and label */
}

`

const Alert = styled.div`
  margin-top: 10px;
  `

const Disclaimer = styled.div`
font-size: small;
color: #404142;
font-weight: normal;
padding: 0px 5px;
height: 0px;
opacity: 0;
margin: 0;
${(props) =>
    props.expand &&
    css`
  animation: ${expandAnimation} 0.5s ease-in-out;
  opacity: 1;
  height: 60px;
  margin-top: 10px;


  @media screen and (max-width: 640px) {
    margin-top: 20px
    }
`}
  `


export default function PressPage({ pageContext, path }) {
  const { releases: posts, hideNavigation } = pageContext
  const [selectedTag, setSelectedTag] = useState(null)
  const [newReleases, setNewReleases] = useState(posts)
  const [loading, setLoading] = useState(true)
  const [subscribed, setSubscribed] = useState(false)
  const [emptyOptions, setEmptyOptions] = useState(false)
  const [query, setQuery] = useState('')
  const releasesPerPage = 5
  const inputReference = useRef(null);
  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [email, setEmail] = useState("")
  const [formData, setFormData] = useState({
    Email: "",
    informationtype: 'prm',
  })

  const [checkboxValues, setCheckboxValues] = useState({
    kmk: false,
    rdv: false,
    prm: true,
    rpt: false,
    inb: false,
    nbr: false,
  });


  const handleCheckboxChange = (name) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [name]: !prevValues[name],
    }));
  };

  useEffect(() => {

    const fetchNews = async () => {
      let cision = "cision"
      if (path.includes("swedish")) {
        cision = "cision-sv"
      }
      const releasesThumbnail = await axios.get(
        `/${cision}/papi/NewsFeed?format=json&pageSize=${releasesPerPage}`
      )
      const newReleases = await Promise.all(
        releasesThumbnail.data.Releases.map(async release => {
          const releaseResponse = await axios.get(
            `/${cision}/papi/Release/${release.EncryptedId}?format=json`
          )
          releaseResponse.data.Release.fromApi = true
          if (
            [
              "Checkin.com Group's IPO oversubscribed by over 2 000 percent, with a total subscription value of SEK 950 million",
              "Checkin.com Group conducts IPO of SEK 45 million on Nasdaq First North Growth Market",
            ].includes(release.Title)
          )
            return {
              ...releaseResponse.data.Release,
              IsRegulatory: true,
            }
          else return releaseResponse.data.Release
        })
      )

      // concat & filter api and prebuilt newsrooms 
      const concatReleases = newReleases.concat(posts)
      const ids = concatReleases.map(release => release.EncryptedId)
      const filteredReleases = concatReleases.filter(({ EncryptedId }, index) => !ids.includes(EncryptedId, index + 1))
      setNewReleases(filteredReleases)
      setLoading(false)


    }

    fetchNews(newReleases)
  }, [])

  const searchClick = () => {
    inputReference.current.focus()
  }

  const filterBySearch = (e) => {
    setQuery(e.target.value)
  }

  const submitEmail = async () => {

    const checkboxValuesString = Object.entries(checkboxValues)
      .filter(([key, value]) => value)
      .map(([key]) => key)
      .join(' ');

    if (checkboxValuesString.length == 0) { setEmptyOptions(true); return; }
    formData.informationtype = checkboxValuesString

    axios.post('https://api.regily.com/sheets',
      {
        "spreadsheetId": "1yK6m8Vv1ZbzzQDOQlsqmJhJfkyU2G6_m8Bb2gRdQRMk",
        "range": null,
        "values": [[
          formData.Email,
          formData.informationtype,
          path.includes("swedish") ? 'sv' : 'en',
          new Date()
        ]
        ]

      }).then(() => {

        setSubmitting(true)
        setTimeout(() => {
          setSuccess(true)
          setFormData({ ...formData, Email: '' })
          setSubscribed(true)
        }, 2000);

        setTimeout(() => {
          setSubmitting(false)
          setSuccess(false)
        }, 2000);
      })

  }

  return (
    <>
      <SEO
        title="Checkin.com Group AB - Latest press releases"
        description="Read the latest regulatory and non-regulatory press releases issued by Checkin.com Group."
        canonical="/newsroom/"
        ogImage="/images/og.jpg"
      />
      <PageContainer>
        {!hideNavigation && <InvestorsNavbar />}
        {hideNavigation && (
          <InvestorsNavbar
            links={[
              {
                to: "/newsroom/",
                text: "Newsroom",
                flag: " 🌎"
              },
              {
                to: "/newsroom/swedish/",
                text: "Newsroom",
                flag: " 🇸🇪"
              },
              {
                to: "/newsroom/mentions/",
                text: "Mentions",
                flag: " 🌎"
              },
              {
                to: "/newsroom/mentions/swedish/",
                text: "Mentions",
                flag: " 🇸🇪"
              },
            ]}
            showOnMobile
          />
        )}
        <Sectionheader>
          {hideNavigation ? <H1>Media relations</H1> : <H2>Media relations</H2>}
          <SectionDescription>
            <br /> For more information or inquiries, please reach out to <a href="mailto:press@checkin.com">press@checkin.com</a>
          </SectionDescription>
          <Search onClick={searchClick}><input ref={inputReference} placeholder="Search" type="text" onChange={filterBySearch} /> </Search>

          <Loader><ClipLoader loading={loading} /> </Loader>
        </Sectionheader>

        <GridContainer>
          <span />
          <PostsContainer>

            {selectedTag === "regulatory" &&
              newReleases
                .filter(post => post.IsRegulatory)
                .filter(post => post.Title.toLowerCase().includes(query.toLowerCase()))
                .map((post, index) => <PostCard fromApi={post.fromApi} key={index} post={post} />)}
            {selectedTag === "non-regulatory" &&
              newReleases
                .filter(post => !post.IsRegulatory)
                .filter(post => post.Title.toLowerCase().includes(query.toLowerCase()))
                .map((post, index) => <PostCard fromApi={post.fromApi} key={index} post={post} />)}
            {!selectedTag &&
              newReleases
                .filter(post => post.Title.toLowerCase().includes(query.toLowerCase()))
                .map((post, index) => <PostCard fromApi={post.fromApi} key={index} post={post} />)}
          </PostsContainer>
          <div>
            <Topics
              selected={selectedTag}
              onSelect={tag => setSelectedTag(tag)}
            />
            <SubscriptionBox color="#4BC1FF">
              {subscribed ? (
                <h3>Thanks for subscribing!</h3>
              ) :
                (
                  <>
                    <h3>Subscribe to our press releases!</h3>
                    <EmailInput>
                      <input
                        onKeyDown={e => {
                          if (e.key === "Enter") {
                            isEmail(email) && submitEmail()
                          }
                        }}
                        value={formData.Email}
                        onChange={(e) => setFormData({ ...formData, Email: e.target.value.trim() })}
                        placeholder="Enter your email" type="text" />

                    </EmailInput>
                    <SubmitButton
                      show={!!formData.Email && isEmail(formData.Email)}
                      onClick={submitEmail}
                      icon={success ? "success" : submitting ? "loading" : "next"}
                    />
                    {emptyOptions && <Alert>Please select one or more types of information.</Alert>}
                    <Options expand={!!formData.Email && isEmail(formData.Email)}>
                      <div>
                        <input
                          type="checkbox"
                          name="kmk"
                          value="kmk"
                          checked={checkboxValues.kmk}
                          onChange={() => handleCheckboxChange('kmk')}
                        />
                        <label>Year-end reports</label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          name="rdv"
                          value="rdv"
                          checked={checkboxValues.rdv}
                          onChange={() => handleCheckboxChange('rdv')}
                        />
                        <label>Annual reports</label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          name="prm"
                          value="prm"
                          checked={checkboxValues.prm}
                          onChange={() => handleCheckboxChange('prm')}
                        />
                        <label>Press releases</label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          name="rpt"
                          value="rpt"
                          checked={checkboxValues.rpt}
                          onChange={() => handleCheckboxChange('rpt')}
                        />
                        <label>Interim reports</label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          name="inb"
                          value="inb"
                          checked={checkboxValues.inb}
                          onChange={() => handleCheckboxChange('inb')}
                        />
                        <label>Invitations</label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          name="nbr"
                          value="nbr"
                          checked={checkboxValues.nbr}
                          onChange={() => handleCheckboxChange('nbr')}
                        />
                        <label>Newsletters</label>
                      </div>
                    </Options>
                    <Disclaimer expand={!!formData.Email && isEmail(formData.Email)} > By subscribing we will process your contact details for the purpose of communicating with you as part of your requested subscription, in line with our <a href='https://checkin.com/privacy-policy'>privacy policy</a>. You may at any time terminate your subscription.</Disclaimer>
                  </>

                )}

              <img src={Sub} />

            </SubscriptionBox>

          </div>
        </GridContainer>
      </PageContainer>
    </>
  )
}
