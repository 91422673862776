import { useState, useEffect } from "react"

const useScroll = (shouldRun = true) => {
  const [amount, setAmount] = useState(
    typeof window === "undefined" ? 0 : window.scrollY
  )

  useEffect(() => {
    const updateScroll = () => {
      setAmount(window.scrollY)
    }

    typeof window !== "undefined" &&
      window.addEventListener("scroll", updateScroll, {
        capture: true,
        passive: true,
      })

    return () =>
      typeof window !== "undefined" &&
      window.removeEventListener("scroll", updateScroll, {
        capture: true,
        passive: true,
      })
  }, [shouldRun])

  return amount
}

export default useScroll
