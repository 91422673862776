import React, {
  useState,
  useRef,
  useImperativeHandle,
  useEffect,
  forwardRef,
  useCallback,
} from "react"
import { useSelector, useDispatch } from "react-redux"
import styled, { css } from "styled-components"
import { rgba } from "polished"
import Moustrap from "mousetrap"
import isEmail from "validator/lib/isEmail"
import _get from "lodash.get"
import axios from "axios"
import Cookies from "js-cookie"
import { format } from "date-fns"

import {
  setBetaEmail,
  setBetaFirstname,
  pushDataLayerEvent,
  pushCustomDataLayerEvent,
} from "../state/actions"


import checkmark from "../assets/images/checkmark-small-black.svg"
import starterIcon from "../assets/images/starter-icon.svg"
import enterpriseIcon from "../assets/images/enterprise-icon.svg"
import plusIcon from "../assets/images/plus-icon.svg"
import minusIcon from "../assets/images/minus-icon.svg"
import closeIcon from "../assets/images/close-icon.svg"
import arrowBack from "../assets/images/arrow-back.svg"
import talkToSales from "../assets/images/talk-to-sales.svg"
import talkToSalesSuccess from "../assets/images/talk-to-sales-success.svg"
import newsletterIcon from "../assets/images/newsletter.svg"
import ContinueButton from "./continue-button"
import serviceURL from "../utils/service-url"
import checkArrow from "../assets/images/check-arrow.svg"
import keyboardUpIcon from "../assets/images/keyboard-up.svg"
import { navigate } from "gatsby"

// const ABOVE_5000_LABEL_ID = "5dd5317198605864525b3e53"
// const WP_SITE_LABEL_ID = "5dd531ad604afc4bddd586c9"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${rgba("#F9F9F8", 0.9)};
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
  z-index: 99999;

  ${props =>
    props.active &&
    css`
      opacity: 1;
      pointer-events: unset;
    `}

  @media screen and (max-width: 980px) {
    overflow: ${props => (props.preventScroll ? "hidden" : "auto")};
    align-items: flex-start;
    background: #fff;
  }
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 600px;
  width: 950px;
  background: #fff;
  border: 1px solid #000000;
  position: relative;
  box-sizing: border-box;
  z-index: 1;

  @media screen and (max-width: 980px) {
    width: 100%;
    height: auto;
    min-height: 100%;
    border: none;
  }
`

const AnimatedBox = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 50px 100px;
  background: #fff;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;

  ${props =>
    props.active &&
    css`
      opacity: 1;
      pointer-events: unset;
    `}

  @media screen and (max-width: 980px) {
    padding: 0 74px 20px;
  }

  @media screen and (max-width: 640px) {
    padding: 80px 20px 20px;

    ${props =>
    props.scrollable &&
    css`
        bottom: auto;
      `}
  }
`

const Header = styled.h2`
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 15px;

  @media screen and (max-width: 640px) {
    font-size: 26px;
    margin-bottom: 6px;

    ${props =>
    props.hideOnMobile &&
    css`
        display: none;
      `}
  }
`

const Subheader = styled.p`
  font-size: 20px;
  line-height: 28px;

  @media screen and (max-width: 640px) {
    font-size: 18px;
    line-height: 24px;

    ${props =>
    props.hideOnMobile &&
    css`
        display: none;
      `}
  }
`

const ActionBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
  margin-top: 50px;

  @media screen and (max-width: 980px) {
    margin-top: 75px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 775px) {
    flex-direction: column;
  }
`

const ActionBox = styled.div`
  border: 1px solid #000;
  width: 361px;
  height: 318px;
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.1);
  padding: 40px;
  box-sizing: border-box;
  position: relative;

  @media screen and (max-width: 980px) {
    width: 300px;
    height: 331px;
    padding: 30px;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 775px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 50px;
    padding: 25px;
    height: 318px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${props =>
    props.disabled &&
    css`
      background: #f0efef;
      box-shadow: none;

      &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 5px;
        top: 5px;
        z-index: -1;
        border: 1px dashed #000;
      }
    `}
`

const ActionBoxIcon = styled.div`
  width: 60px;
  height: 60px;
  border: 1px solid #000;
  border-radius: 50%;
  position: relative;
  box-sizing: border-box;
  background: url(${enterpriseIcon}) no-repeat center, #ffd63e;

  ${props =>
    props.disabled &&
    css`
      background: url(${starterIcon}) no-repeat 50% 130%, #f0efef;
    `}

  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 2px;
    top: 2px;
    border: 1px dashed #000;
    border-radius: 50%;
    z-index: -1;
  }
`

const ActionBoxStripe = styled.div`
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 13px;
`

const ActionBoxHeader = styled.h3`
  font-size: 24px;
  line-height: 32px;
  margin: 15px 0;

  @media screen and (max-width: 980px) {
    font-size: 22px;
  }
`

const ActionBoxFeature = styled.p`
  font-size: 15px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 980px) {
    font-size: 16px;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  &::before {
    content: "";
    height: 28px;
    width: 28px;
    background: url(${checkmark}) no-repeat center/50%;
    margin-right: 5px;

    @media screen and (max-width: 980px) {
      height: 16px;
    }
  }
`

const ActionBoxButton = styled.button`
  background: #ffd63e;
  border: 1px solid #000;
  outline: none;
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.1);
  padding: 18px 40px;
  font-size: 18px;
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;

  @media screen and (max-width: 640px) {
    width: 100%;
  }
`

const PackagesButton = styled.button`
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  margin-top: 24px;
`

const PlansContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #000;
  margin-top: 35px;
  box-sizing: border-box;

  @media screen and (max-width: 640px) {
    margin-top: 0;
  }
`

const PlansContainerSelector = styled.div`
  display: flex;
  width: 100%;
`

const Plan = styled.div`
  flex: 0 0 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  font-size: 14px;
  font-weight: 700;
  border-right: 1px solid #000;
  background: ${props => props.background || "#f9f9f9"};
  z-index: 1;
  box-sizing: border-box;
  padding: 0 20px;
  position: relative;

  @media screen and (max-width: 820px) {
    flex: 0 0 40%;
    padding: 0 10px;
  }

  p {
    flex: 0;
    line-height: 22px;
  }

  &:last-child {
    border-right: none;
  }

  ${props =>
    props.selected
      ? css`
          flex-grow: ${props => props.flex};
          justify-content: ${props =>
          props.starter ? "center" : "flex-start"};
          box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.1);
          z-index: 2;

          @media screen and (max-width: 820px) {
            flex-grow: 0 !important;
            flex: 0 0 ${props => (props.starter ? "50%" : "60%")};
          }

          @media screen and (max-width: 375px) {
            flex: 0 0 50% !important;
          }
        `
      : css`
          @media screen and (max-width: 820px) {
            display: none;
          }
        `}

  ${props =>
    props.selectable &&
    css`
      @media screen and (max-width: 820px) {
        display: flex;
        border-right: none;
        flex: 0 0 40%;
      }

      @media screen and (max-width: 375px) {
        flex: 0 0 50% !important;
      }
    `}

    ${props =>
    props.fullWidth &&
    css`
        @media screen and (max-width: 820px) {
          flex-grow: 2 !important;
        }
      `}
`

const PlanIcon = styled(ActionBoxIcon)`
  width: 40px;
  height: 40px;
  margin-right: 20px;
  background: url(${plusIcon}) no-repeat center, #f0efef;
  pointer-events: none;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 980px) and (min-width: 821px) {
    margin-right: 10px;
  }

  @media screen and (max-width: 375px) {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background: url(${plusIcon}) no-repeat center/80%, #f0efef;
  }

  &::after {
    display: none;
  }

  ${props =>
    props.selected &&
    css`
      background: url(${enterpriseIcon}) no-repeat center/70%,
        ${props => props.background};
      box-shadow: none;

      @media screen and (max-width: 375px) {
        width: 30px;
        height: 30px;
        background: url(${enterpriseIcon}) no-repeat center/70%,
          ${props => props.background};
      }

      &::after {
        display: initial;
      }
    `}

  ${props =>
    props.starter &&
    css`
      background: url(${starterIcon}) no-repeat 50% 120%/70%,
        ${props => props.background};
    `}

  ${props =>
    props.selectable &&
    css`
      background: url(${plusIcon}) no-repeat center,
        ${props => props.background};
      cursor: pointer;
      pointer-events: unset;
    `}
`

const RemoveButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 12px;
    font-weight: normal;

    @media screen and (max-width: 980px) {
      span {
        display: none;
      }
    }
  }

  @media screen and (max-width: 375px) {
    position: absolute;
    top: 5px;
    right: 5px;

    p {
      display: none;
    }
  }
`

const RemoveButton = styled.button`
  display: flex;
  flex-direction: column;
  box-shadow: none;
  background: url(${minusIcon}) no-repeat center, transparent;
  outline: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 50%;
  margin-bottom: 5px;
`

const PlanDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 225px;
  border-top: 1px solid #000;
  background: #f9f9f9;
  z-index: 3;
  padding: 30px;
  box-sizing: border-box;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 370px;
  }
`

const PlanDetailsDescription = styled.p`
  font-size: 18px;
  font-weight: 700;
  width: 150px;
  line-height: 26px;
  order: 1;

  @media screen and (max-width: 640px) {
    width: auto;
  }
`

const PlanDetailsFeatures = styled.div`
  display: flex;
  flex-direction: column;
  order: 2;

  @media screen and (max-width: 640px) {
    order: 3;
  }

  h4 {
    margin-bottom: 18px;
    font-size: 15px;
  }
`

const PlanDetailsPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  order: 3;

  @media screen and (max-width: 640px) {
    order: 2;
    flex-direction: row;
    width: auto;
    margin: 32px 0 12px;
  }

  h1 {
    font-size: 40px;
    margin-bottom: 6px;

    @media screen and (max-width: 640px) {
      margin: 0;
      margin-right: 12px;
    }

    @media screen and (max-width: 375px) {
      font-size: 30px;
    }
  }

  p {
    font-size: 15px;
  }
`

const FeaturesBox = styled.div`
  pointer-events: ${props => (props.visible ? "all" : "none")};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.2s ease;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${rgba("#F9F9F8", 0.9)};

  @media screen and (max-width: 980px) {
    position: absolute;
  }
`

const FeaturesBoxContent = styled.div`
  width: 655px;
  height: 363px;
  background: #fff;
  border: 1px solid #000;
  position: relative;
  z-index: 1;
  padding: 80px 100px;
  box-sizing: border-box;

  @media screen and (max-width: 640px) {
    width: calc(100% - 40px);
    height: 372px;
    padding: 50px 20px;
  }

  h2 {
    font-size: 32px;
    margin-bottom: 16px;
  }
`

const FeatureBoxIcon = styled(ActionBoxIcon)`
  width: 100px;
  height: 100px;
  position: absolute;
  top: -50px;
  left: 100px;
  background: ${props =>
    props.starter
      ? `url(${starterIcon}) no-repeat 50% 120%/75%`
      : `url(${enterpriseIcon}) no-repeat center/75%`},
    ${props => props.background};

  @media screen and (max-width: 640px) {
    width: 60px;
    height: 60px;
    left: 30px;
    top: -30px;
  }
`

const CloseButton = styled.button`
  outline: none;
  box-shadow: none;
  background: url(${closeIcon}) no-repeat center, transparent;
  border: none;
  position: absolute;
  bottom: calc(100% + 20px);
  right: 0;
  height: 21px;
  width: 21px;
  cursor: pointer;
`

const BackButton = styled(CloseButton)`
  width: 27px;
  height: 18px;
  background: url(${arrowBack}) no-repeat center, transparent;
  right: unset;
  top: 30px;
  left: 30px;

  @media screen and (max-width: 980px) {
    left: 20px;
  }
`

const ContactBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 100px;
  box-sizing: border-box;
  align-items: flex-start;
  position: relative;

  @media screen and (max-width: 980px) {
    padding: 200px 100px 0;
  }

  @media screen and (max-width: 640px) {
    padding: 0;
    flex: 1;
  }
`

const ContactBoxWarning = styled(ContactBox)`
  justify-content: center;

  @media screen and (max-width: 980px) {
    justify-content: flex-start;
    padding-top: 200px;
  }

  @media screen and (max-width: 640px) {
    justify-content: flex-start;
    padding-top: 130px;
  }
`

const ContactBoxWarningButtons = styled.div`
  display: flex;
  margin-top: 70px;

  @media screen and (max-width: 640px) {
    flex: 1;
    align-items: flex-end;
    width: 100%;
  }
`

const WarningHeaderContainer = styled.div`
  margin-bottom: 40px;
  position: relative;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${rgba("#fbd65d", 0.25)};
  cursor: pointer;

  @media screen and (max-width: 980px) {
    margin-bottom: 20px;
  }
`

const WarningHeader = styled.h3`
  font-size: 26px;

  @media screen and (max-width: 980px) {
    font-size: 24px;
  }
`

const WarningSubheader = styled(Subheader)`
  @media screen and (max-width: 980px) {
    font-size: 15px;
  }
`

const WarningArrow = styled.div`
  width: 35px;
  height: 30px;
  background: url(${checkArrow}) no-repeat center;
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50px;

  &::after {
    content: "Please double-check";
    color: #ffab00;
    font-size: 16px;
    position: absolute;
    left: calc(100% + 10px);
    top: -8px;
    font-weight: normal;
    width: 180px;
  }
`

const Editbutton = styled(ContinueButton)`
  width: 153px;
  background: #fbd65d;
  margin-right: 20px;
  font-size: 18px;

  @media screen and (max-width: 640px) {
    flex: 1;
    width: auto;
  }
`

const CorrectButton = styled(Editbutton)`
  background: #fff;
  margin-right: 0;
`

const ContactIcon = styled.div`
  width: 200px;
  height: 129px;
  background: url(${talkToSales}) no-repeat center;
  margin-bottom: 60px;
  transform: translateX(-60px);

  @media screen and (max-width: 980px) {
    display: none;
    transform: none;
  }
`

const SuccessIcon = styled(ContactIcon)`
  width: 145px;
  height: 125px;
  background: url(${talkToSalesSuccess}) no-repeat center/contain;
  transform: none;

  @media screen and (max-width: 980px) {
    display: initial;
    width: 73px;
    height: 62px;
  }
`

const NewsLetterIcon = styled(ContactIcon)`
  width: 136px;
  height: 117px;
  background: url(${newsletterIcon}) no-repeat center/contain;
  transform: none;
`

const EmailInputWrapper = styled.div`
  margin-bottom: 40px;
  margin-top: 40px;
  position: relative;
  display: flex;
  width: 350px;

  @media screen and (max-width: 980px) {
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin-top: 30px;
    margin-bottom: 16px;

    ${props =>
    props.forceShow &&
    css`
        display: flex;
      `}
  }

  &::after {
    content: "Email";
    position: absolute;
    left: 0;
    top: 20px;
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;

    ${props =>
    props.showLabel &&
    css`
        transform: translate3d(0, -25px, 0);
        opacity: 0.5;
      `}
  }
`

const FakeEmailPlaceholder = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 26px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
  }
  color: ${rgba("#000000", 0.2)};
`

const EmailInput = styled.input`
  width: 321px;
  background: transparent;
  outline: none;
  border: none;
  font-size: ${props => (props.scaleText ? "20px" : "26px")};
  font-weight: 700;
  position: relative;
  border-bottom: 1px solid #000;
  box-shadow: 0 4px 0 0 ${rgba("#000", 0.1)};
  transition: all 0.5s ease;
  border-radius: 0;
  height: 60px;
  display: flex;
  align-items: center;

  &::-moz-placeholder {
    color: #000;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px white inset;
  }

  ${props =>
    props.hideBorder &&
    css`
      border-bottom: 1px solid ${rgba("#000", 0)};
      box-shadow: 0 4px 0 0 ${rgba("#000", 0)};
    `}

  @media screen and (max-width: 980px) {
    width: 100%;
    padding-right: 50px;
    font-size: ${props => (props.scaleText ? "14px" : "20px")};
  }
`

const SubmitButton = styled(ContinueButton)`
  position: absolute;
  width: 50px;
  height: 50px;
  right: 10px;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.5s ease;
  background: #ffd63e;

  ${props =>
    props.success &&
    css`
      background: #b6fad9;
    `}
`

const FakeInput = styled.input`
  position: absolute;
  opacity: 0;
  transform: translateY(-200px);
`

const KeyboardUp = styled.button`
  width: 100%;
  height: 60px;
  background: url(${keyboardUpIcon}) no-repeat center, ${rgba("#19191A", 0.1)};
  position: absolute;
  bottom: 0;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  display: none;

  @media screen and (max-width: 980px) {
    display: block;
    align-self: center;
  }
`

const EmailFormSubheader = styled(Subheader)`
  @media screen and (max-width: 980px) {
    display: none;
  }
`

const EmailFormMobileSubheader = styled(Subheader)`
  display: none;
  font-size: 15px;
  opacity: ${props => (props.hide ? 0 : 1)};
  transition: opacity 0.2s ease;

  @media screen and (max-width: 980px) {
    display: initial;
    font-size: 15px;
  }
`

const InputSizeTester = styled.p`
  font-size: 26px;
  font-weight: 700;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: -1;

  @media screen and (max-width: 980px) {
    font-size: 20px;
  }
`

const FeatureBoxCloseButton = styled(CloseButton)`
  right: 30px;
  bottom: auto;
  top: 30px;
`

let lookupResponse = null

const GetStarted = forwardRef(({ onDismiss }, ref) => {
  const dispatch = useDispatch()
  const [active, setActive] = useState(false)
  const [page, setPage] = useState("emailForm")
  const plans = [
    {
      name: "Starter",
      description: "Set it up yourself",
      color: "#B4DEEC",
      limit: "50",
      price: "29",
      featuresShort: ["Self-integration", "Customization options"],
      featuresLong: [
        "Self-integration",
        "Customization options",
        "Self-improving conversion optimization",
        "Automatic updates without release",
        "€0.49 per additional sign-up",
      ],
    },
    {
      name: "Enterprise Features",
      description: "Guaranteed conversion increase",
      color: "#FFD63E",
      limit: "5 000",
      price: "1 900",
      featuresShort: ["Expert preconfiguration", "Integration support"],
      featuresLong: [
        "Expert preconfiguration and support",
        "Conversion increase guarantee",
        "Fully white labeled",
        "Self-improving conversion optimization",
        "€0.39 per additional sign-up",
      ],
    },
    {
      name: "More volume",
      description: "Guaranteed conversion increase",
      color: "#FF986B",
      limit: "15 000",
      price: "3 900",
      featuresShort: ["Expert preconfiguration", "Integration support"],
      featuresLong: [
        "Expert preconfiguration and support",
        "Conversion increase guarantee",
        "Fully white labeled",
        "Self-improving conversion optimization",
        "€0.19 per additional sign-up",
      ],
    },
    {
      name: "Mega volume",
      description: "Guaranteed conversion increase",
      color: "#C894D6",
      limit: "50 000",
      price: "5 900",
      featuresShort: ["Expert preconfiguration", "Integration support"],
      featuresLong: [
        "Expert preconfiguration and support",
        "Conversion increase guarantee",
        "Fully white labeled",
        "Self-improving conversion optimization",
        "€0.10 per additional sign-up",
      ],
    },
  ]

  const [planLevel, setPlanLevel] = useState(0)
  const selectedPlan = plans[planLevel]
  const nextPlans = plans.slice(planLevel + 1)
  const containerRef = useRef()
  const emailInputRef = useRef()
  const [featuresBoxVisible, setFeaturesBoxVisible] = useState(false)
  const [email, setEmail] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [showSuccessPage, setShowSuccessPage] = useState(false)
  const fakeInputRef = useRef()
  const isWordpress = useSelector(state =>
    _get(state, "scanning.results.IsWordPress.wordpress.isWP")
  )
  const website = useSelector(state => _get(state, "global.website"))
  const firstname = useSelector(state => _get(state, "beta.firstname"))
  const [sendFullData, setSendFullData] = useState(false)
  const [companyName, setCompanyName] = useState("")
  const isMobile = useSelector(state => _get(state, "global.isMobile"))
  const [showKeyboardUp, setShowKeyboardUp] = useState(false)
  const inputSizeTester = useRef()
  const [scaleText, setscaleText] = useState(false)
  const [isRemote, setIsRemote] = useState(false)
  const [isNewsletter, setIsNewsletter] = useState(false)
  const [isNewsletterDone, setIsNewsletterDone] = useState(false)
  const searchParams = useSelector(state => _get(state, "global.searchParams"))

  useEffect(() => {
    setscaleText(
      inputSizeTester.current &&
      inputSizeTester.current.clientWidth >=
      emailInputRef.current.clientWidth - 60
    )
  }, [email])

  const onEmailChange = e => {
    setEmail(e.target.value.trim())
  }

  const open = (
    page,
    sendFullData = false,
    isRemote = false,
    isNewsletter = false,
    isNewsletterDone = false
  ) => {
    page && setPage(page)
    setSendFullData(sendFullData)
    isRemote && setIsRemote(true)
    isNewsletter && setIsNewsletter(true)
    if (isNewsletterDone) {
      setShowSuccessPage(true)
      setIsNewsletterDone(true)
    }
    setTimeout(() => {
      setActive(true)
    }, 200)
  }
  const close = useCallback(() => {
    setTimeout(() => {
      setPage("emailForm")
      setPlanLevel(0)
      setEmail("")
      setSuccess(false)
      setShowSuccessPage(false)
      setIsRemote(false)
      setIsNewsletter(false)
      setIsNewsletterDone(false)
    }, 500)
    setActive(false)
    onDismiss && onDismiss()
  }, [onDismiss])

  useImperativeHandle(ref, () => ({
    el: ref.current,
    open,
    close,
  }))

  useEffect(() => {
    const dismiss = () => {
      onDismiss && onDismiss()
      close()
    }

    const onWindowClick = e => {
      if (e.target === containerRef.current) {
        onDismiss && onDismiss()
        close()
      }
    }

    Moustrap.bind(["esc"], dismiss)
    window.addEventListener("click", onWindowClick)

    return () => {
      Moustrap.unbind(["esc"])
      window.removeEventListener("click", onWindowClick)
    }
  }, [onDismiss, close])

  const editEmail = () => {
    fakeInputRef.current && fakeInputRef.current.focus()
    setTimeout(() => {
      setPage("emailForm")
      emailInputRef.current.select()
    }, 100)
  }

  const submitData = async (e, skipEmailCheck = false) => {
    if (email === "" || !emailInputRef.current.reportValidity()) return
    setSubmitting(true)

    try {
      const response =
        lookupResponse && skipEmailCheck
          ? { ...lookupResponse }
          : await axios.post(`${serviceURL().regilyAPI}/emails`, {
            email,
          })

      lookupResponse = null

      if (response.data.result !== "deliverable" && !skipEmailCheck) {
        lookupResponse = response
        setSubmitting(false)
        emailInputRef.current.blur()
        setPage("emailWarning")
        return
      }

      dispatch(setBetaEmail(email))
      dispatch(
        setBetaFirstname(_get(response, "data.person.name.givenName", ""))
      )

      setCompanyName(_get(response, "data.company.name", ""))

      if (isNewsletter) {
        try {
          await axios.post(`${serviceURL().api}/hubspot-contacts`, {
            email: email,
            leadSource: "Regily V2.1",
            getStartedOptIn: "Newsletter",
            referrer: searchParams.referrer,
            utm: searchParams.utm,
            gclid: searchParams.gclid,
            fbclid: searchParams.fbclid,
            utmTerm: searchParams.utmTerm,
            newsletter_from_regily_com: "yes",
            regilyPagePath: `/${window.location.pathname.replace(
              "/",
              ""
            )} ${format(new Date(), "yyyy-MM-dd")}`,
            regilyLeadType: "newsletter",
          })

          setSubmitting(false)
          setSuccess(true)
          setTimeout(() => {
            setShowSuccessPage(true)
            setTimeout(() => {
              setSuccess(false)
            }, 1000)
          }, 2000)
        } catch (error) {
          console.error(error)
        }

        return
      }

      //       try {
      //         let cardLabels = ABOVE_5000_LABEL_ID
      //         if (isWordpress) cardLabels += `,${WP_SITE_LABEL_ID}`

      //         axios.post(`${serviceURL().regilyAPI}/trello`, {
      //           cardName: _get(response, "data.company.name")
      //             ? `${_get(response, "data.company.name")} / ${email}`
      //             : email,
      //           idList: "5b3a11b3a4348951e1411837",
      //           idLabels: cardLabels,
      //           dataString: `*${new Date().toUTCString()}*
      // ${
      //   _get(response, "data.company.name")
      //     ? `**${_get(response, "data.company.name")}**`
      //     : ""
      // }
      // ${website}
      // ${
      //   _get(response, "data.company.category.industry")
      //     ? `- ${_get(response, "data.company.category.industry")}`
      //     : ""
      // }
      // ${
      //   _get(response, "data.company.metrics.employees")
      //     ? `- ${_get(response, "data.company.metrics.employees")}`
      //     : ""
      // }
      // ${
      //   _get(response, "data.company.location")
      //     ? `- ${_get(response, "data.company.location")}`
      //     : ""
      // }

      // **${email}**
      // ${
      //   _get(response, "data.person.name.fullName")
      //     ? `${_get(response, "data.person.name.fullName")}`
      //     : ""
      // }
      // ${
      //   _get(response, "data.company.phone")
      //     ? `${_get(response, "data.company.phone")}`
      //     : ""
      // }
      // `,
      //         })
      //       } catch (error) {
      //         console.error("Failed to save trello card!", error)
      //       }

      if (!sendFullData) {
        try {
          axios.post(`${serviceURL().api}/hubspot-contacts`, {
            email: email,
            leadSource: "Regily V2.1",
            website: _get(response, "data.company.domain", ""),
            referrer: searchParams.referrer,
            utm: searchParams.utm,
            gclid: searchParams.gclid,
            fbclid: searchParams.fbclid,
            utmTerm: searchParams.utmTerm,
            getStartedOptIn: isRemote ? "Remote" : "",
            cuid: Cookies.get("gtag-id"),
            regilyPagePath: `/${window.location.pathname.replace(
              "/",
              ""
            )} ${format(new Date(), "yyyy-MM-dd")}`,
            regilyLeadType: "get_started",
          })
        } catch (error) {
          console.error(error)
        }
      } else {
        try {
          axios.post(`${serviceURL().api}/hubspot-contacts`, {
            email: email,
            leadSource: "Regily V2.1",
            website: website,
            isWordpressSite: isWordpress,
            cuid: Cookies.get("gtag-id"),
            isSiteScanned: true,
            referrer: searchParams.referrer,
            utm: searchParams.utm,
            gclid: searchParams.gclid,
            fbclid: searchParams.fbclid,
            utmTerm: searchParams.utmTerm,
            getStartedOptIn: isRemote ? "Remote" : "",
            regilyPagePath: `/${window.location.pathname.replace(
              "/",
              ""
            )} ${format(new Date(), "yyyy-MM-dd")}`,
            regilyLeadType: "site_scanner",
          })

          dispatch(
            pushCustomDataLayerEvent({
              event: "get_started_talk_to_sales_submitted",
              eventCategory: "get_started",
              eventAction: "enterprise_select",
              eventLabel: "modal_topmenu",
            })
          )
        } catch (error) {
          console.error(error)
        }
      }

      dispatch(pushDataLayerEvent("reg_2_success", null))

      dispatch(pushDataLayerEvent("reg_success"))

      setSubmitting(false)
      setSuccess(true)
      setTimeout(() => {
        if (isRemote) return setShowSuccessPage(true)
        setPage("home")
        setTimeout(() => {
          setSuccess(false)
        }, 1000)
      }, 2000)
    } catch (error) {
      console.error(error)
      setSubmitting(false)
    }
  }

  const onTouchMove = e => {
    e.preventDefault()
  }

  useEffect(() => {
    if (page !== "home" && active)
      window.addEventListener("touchmove", onTouchMove, { passive: false })
    else
      window.removeEventListener("touchmove", onTouchMove, { passive: false })

    return () =>
      window.removeEventListener("touchmove", onTouchMove, { passive: false })
  }, [page, active])

  return (
    <Container
      active={active}
      preventScroll={page !== "home"}
      ref={containerRef}
    >
      <Box>
        <CloseButton
          onClick={() => {
            close()
          }}
        />
        <AnimatedBox active={showSuccessPage}>
          <ContactBox>
            <SuccessIcon />
            {!!firstname && !isNewsletterDone && (
              <Header>Perfect, {firstname}!</Header>
            )}
            {!firstname && !isNewsletterDone && <Header>Perfect!</Header>}
            {isNewsletterDone && <Header>Thanks!</Header>}
            {isNewsletter && (
              <Subheader>You are now subscribed to our newsletter!</Subheader>
            )}
            {isNewsletterDone && (
              <Subheader>
                You will receive the meeting invitation shortly.
              </Subheader>
            )}
            {!!companyName && !isNewsletter && !isNewsletterDone && (
              <Subheader>
                Before we configure your new flow, let's talk a bit about your
                requirements and how the framework functions.
              </Subheader>
            )}

            {!companyName && !isNewsletter && !isNewsletterDone && (
              <Subheader>
                Before we configure your new flow, let's talk a bit about your
                requirements and how the framework functions.
              </Subheader>
            )}

            {!isNewsletterDone && (
              <ActionBoxButton
                onClick={async () => {
                  close()
                  await dispatch(
                    pushCustomDataLayerEvent({
                      event: "get_started",
                      eventCategory: "get_started",
                      eventAction: "calendly_click",
                    })
                  )
                  navigate("meeting-booker")
                }}
              >
                Pick a time
              </ActionBoxButton>
            )}

            {isNewsletterDone && (
              <ActionBoxButton
                onClick={async () => {
                  close()
                  navigate("/")
                }}
              >
                Okay
              </ActionBoxButton>
            )}
          </ContactBox>
        </AnimatedBox>

        <AnimatedBox
          active={page === "home" && !showSuccessPage}
          className="scrollable"
          scrollable
        >
          <BackButton
            onClick={() => {
              editEmail()
            }}
          />

          <FakeInput ref={fakeInputRef} readOnly />

          <Header>Which one are you interested in?</Header>
          <ActionBoxContainer>
            <ActionBox>
              <div
                style={{
                  zIndex: 1,
                  position: "absolute",
                  top: "-30px",
                  left: "30px",
                }}
              >
                <ActionBoxIcon />
              </div>
              <ActionBoxHeader>We preconfigure everything</ActionBoxHeader>
              <ActionBoxFeature>Expert preconfiguration</ActionBoxFeature>
              <ActionBoxFeature>Integration support</ActionBoxFeature>
              <ActionBoxFeature>Conversion increase guarantee</ActionBoxFeature>
              <ActionBoxButton
                data-cy="gs-plan-continue"
                onClick={() => {
                  containerRef.current.scrollTo({
                    behavior: "smooth",
                    top: 0,
                  })
                  try {
                    axios.post(`${serviceURL().api}/hubspot-contacts`, {
                      email: email,
                      getStartedOptIn: "Enterprise",
                      referrer: searchParams.referrer,
                      utm: searchParams.utm,
                      gclid: searchParams.gclid,
                      fbclid: searchParams.fbclid,
                      utmTerm: searchParams.utmTerm,
                    })
                  } catch (error) {
                    console.error(error)
                  }
                  dispatch(
                    pushCustomDataLayerEvent({
                      event: "get_started_talk_to_sales_click",
                      eventCategory: "get_started",
                      eventAction: "enterprise_select",
                      eventLabel: "modal_topmenu",
                    })
                  )
                  setShowSuccessPage(true)
                }}
              >
                Continue
              </ActionBoxButton>
            </ActionBox>
            <ActionBox disabled>
              <div
                style={{
                  zIndex: 1,
                  position: "absolute",
                  top: "-30px",
                  left: "30px",
                }}
              >
                <ActionBoxIcon disabled />
              </div>
              <ActionBoxHeader>Set it up yourself</ActionBoxHeader>
              <ActionBoxFeature>Self-integration</ActionBoxFeature>
              <ActionBoxFeature>€29/month</ActionBoxFeature>
              <ActionBoxStripe>Our beta is full</ActionBoxStripe>
            </ActionBox>
          </ActionBoxContainer>
          <PackagesButton
            onClick={() => {
              containerRef.current.scrollTo({
                behavior: "smooth",
                top: 0,
              })
              dispatch(
                pushCustomDataLayerEvent({
                  event: "get_started_pricing_click",
                  eventCategory: "get_started",
                  eventAction: "pricing_view",
                  eventLabel: "modal_topmenu",
                })
              )
              setPage("packages")
            }}
          >
            See our packages
          </PackagesButton>
        </AnimatedBox>

        <AnimatedBox active={page === "packages" && !showSuccessPage}>
          <BackButton
            onClick={() => {
              setPlanLevel(0)
              setPage("home")
            }}
          />
          <FeaturesBox visible={featuresBoxVisible}>
            <FeaturesBoxContent>
              <FeatureBoxIcon
                starter={selectedPlan.name === "Starter"}
                background={selectedPlan.color}
              />

              <FeatureBoxCloseButton
                onClick={() => setFeaturesBoxVisible(false)}
              />

              <h2>{selectedPlan.name}</h2>

              {selectedPlan.featuresLong.map((feature, index) => (
                <ActionBoxFeature key={index}>{feature}</ActionBoxFeature>
              ))}
            </FeaturesBoxContent>
          </FeaturesBox>
          <Header hideOnMobile>Packages</Header>
          <Subheader hideOnMobile>Simple pricing, no surprises</Subheader>
          <PlansContainer>
            <PlansContainerSelector>
              <Plan
                background={selectedPlan.color}
                flex={planLevel + 1}
                selected
                starter={selectedPlan.name === "Starter"}
                fullWidth={nextPlans.length === 0}
              >
                <PlanIcon
                  selected
                  starter={selectedPlan.name === "Starter"}
                  background={selectedPlan.color}
                />
                <p>{selectedPlan.name}</p>

                {planLevel > 0 && (
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <RemoveButtonContainer>
                      <RemoveButton onClick={() => setPlanLevel(v => v - 1)} />
                      <p>
                        Remove <span>features</span>
                      </p>
                    </RemoveButtonContainer>
                  </div>
                )}
              </Plan>
              {nextPlans.map((plan, index) => (
                <Plan key={index} selectable={index === 0}>
                  <PlanIcon
                    selectable={index === 0}
                    background={plan.color}
                    onClick={() => {
                      dispatch(
                        pushCustomDataLayerEvent({
                          event: "get_started_pricing_interaction",
                          eventCategory: "get_started",
                          eventAction: "pricing_interaction",
                          eventLabel: "modal_topmenu",
                        })
                      )
                      setPlanLevel(v => v + 1)
                    }}
                  />
                  <p>{plan.name}</p>
                </Plan>
              ))}
            </PlansContainerSelector>
            <PlanDetails>
              <PlanDetailsDescription>
                {selectedPlan.description}
              </PlanDetailsDescription>
              <PlanDetailsFeatures>
                <h4>{selectedPlan.limit} monthly sign-ups</h4>
                {selectedPlan.featuresShort.map((feature, index) => (
                  <ActionBoxFeature key={index}>{feature}</ActionBoxFeature>
                ))}
                <PackagesButton onClick={() => setFeaturesBoxVisible(true)}>
                  See all features
                </PackagesButton>
              </PlanDetailsFeatures>
              <PlanDetailsPrice>
                <h1>€{selectedPlan.price}</h1>
                <p>per month</p>
              </PlanDetailsPrice>
            </PlanDetails>
          </PlansContainer>
        </AnimatedBox>

        <AnimatedBox active={page === "emailForm" && !showSuccessPage}>
          {isMobile && (
            <BackButton
              onClick={() => {
                close()
              }}
            />
          )}

          <form
            action="#"
            style={{ display: "contents" }}
            onSubmit={e => e.preventDefault()}
          >
            <ContactBox>
              {isNewsletter ? <NewsLetterIcon /> : <ContactIcon />}
              <Header>
                {isNewsletter
                  ? "Don’t miss a thing!"
                  : isRemote
                    ? "Get started with Checkin Connect"
                    : sendFullData
                      ? "Your new checkin is ready!"
                      : "Get started"}
              </Header>
              <EmailFormSubheader>
                {isNewsletter
                  ? "Subscribe to our newsletter to get the latest product updates and insights."
                  : isRemote
                    ? "Increase conversion on any trusted third party site"
                    : sendFullData
                      ? "Your new checkin experience is guaranteed to increase your conversion rate"
                      : "Your new checkin experience is guaranteed to increase your conversion rate"}
              </EmailFormSubheader>

              <EmailInputWrapper showLabel={!!email}>
                <InputSizeTester ref={inputSizeTester}>{email}</InputSizeTester>
                {!email && (
                  <FakeEmailPlaceholder>
                    <p>Enter your email</p>
                  </FakeEmailPlaceholder>
                )}
                <EmailInput
                  id="get-started-email-input"
                  type="email"
                  data-cy="gs-email-input"
                  ref={emailInputRef}
                  onChange={onEmailChange}
                  value={email}
                  hideBorder={isEmail(email)}
                  scaleText={scaleText}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      isEmail(email) && submitData()
                    }
                  }}
                  onFocus={() => {
                    setShowKeyboardUp(false)
                    setTimeout(() => {
                      window.scrollTo(0, 0)
                      document.body.scrollTop = 0
                    }, 200)
                  }}
                  onBlur={() => setShowKeyboardUp(true)}
                />

                <SubmitButton
                  show={!!email && isEmail(email)}
                  onClick={submitData}
                  disabled={submitting || success}
                  success={success}
                  icon={success ? "success" : submitting ? "loading" : "next"}
                ></SubmitButton>
              </EmailInputWrapper>
              <EmailFormMobileSubheader hide={isEmail(email)}>
                {isNewsletter
                  ? "Subscribe to our newsletter to get the latest product updates and insights."
                  : isRemote
                    ? "Increase conversion on any trusted third party site"
                    : sendFullData
                      ? "Replacing your forms with the improved sign-up experience is guaranteed to increase your conversion rate"
                      : "Your new checkin experience is guaranteed to increase your conversion rate"}
              </EmailFormMobileSubheader>
              {showKeyboardUp && (
                <KeyboardUp onClick={() => emailInputRef.current.select()} />
              )}
            </ContactBox>
          </form>
        </AnimatedBox>

        <AnimatedBox active={page === "emailWarning" && !showSuccessPage}>
          <BackButton onClick={editEmail} />
          <ContactBoxWarning>
            <WarningHeaderContainer onClick={editEmail}>
              <WarningHeader>{email}</WarningHeader>
              <WarningArrow />
            </WarningHeaderContainer>

            <WarningSubheader>
              Are you sure this email is correct?
            </WarningSubheader>
            <ContactBoxWarningButtons>
              <Editbutton onClick={editEmail}>Edit</Editbutton>
              <CorrectButton
                onClick={e => {
                  setPage("emailForm")
                  setTimeout(() => {
                    submitData(e, true)
                  }, 100)
                }}
              >
                It's correct
              </CorrectButton>
            </ContactBoxWarningButtons>
          </ContactBoxWarning>
        </AnimatedBox>
      </Box>
    </Container>
  )
})

export default GetStarted
