import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

import menuIcon from "../assets/images/menu-icon.svg"
import closeMenuIcon from "../assets/images/close-menu-icon.svg"


const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2000;
  pointer-events: none;

  ${props =>
    props.isOpen &&
    css`
      z-index: 2200;
    `}

  @media screen and (min-width: 981px) {
    display: none;
  }
`

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #b1f1d4;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  padding-bottom: 100px;
  box-sizing: border-box;
  z-index: -1;

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      pointer-events: all;
    `};

  @media screen and (max-width: 980px) and (min-width: 641px) {
    padding-top: 200px;
    padding-bottom: 300px;
  }
`

const OpenButton = styled.button`
  display: flex;
  outline: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border: 1px solid #000;
  position: absolute;
  bottom: 20px;
  left: 20px;
  pointer-events: none;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.2s ease;

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border: 1px dashed #000;
    top: 6px;
    left: 6px;
    z-index: -1;
    box-sizing: border-box;
  }

  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: url(${menuIcon}) no-repeat center, #fbd65d;
    box-sizing: border-box;
  }

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      pointer-events: all;
    `}

  ${props =>
    props.pushUp &&
    css`
      transform: translate3d(0, -75px, 0);
    `}
`

const CloseButton = styled(OpenButton)`
  justify-content: center;
  opacity: 0;
  pointer-events: none;

  &::after {
    background: url(${closeMenuIcon}) no-repeat center, #000;
  }

  span {
    position: absolute;
    width: 80%;

    &:nth-child(1) {
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      transform: rotate(-45deg);
    }
  }

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`


const NavLink = styled(Link)`
  position: relative;
  font-size: 22px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  padding: 30px 0;
  outline: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  &.active {
    text-decoration: underline;
  }

  @media screen and (max-width: 375px) {
    padding: 10px 0;
  }
`


const FullScreenNav = ({ hideOnFullScroll, location }) => {
  const [open, setOpen] = useState(false)
  const windowHeight = useSelector(state => state.global.windowHeight)
  const isMobile = useSelector(state => state.global.isMobile)

  const hideMenu = () =>
    ["igaming", "calculator"].find(v => location.includes(v))

  useEffect(() => {
    if (!isMobile) return
    if (window.HubSpotConversations) {
      if (!window.HubSpotConversations.widget.status().loaded)
        window.HubSpotConversations.widget.load()
    } else {
      window.hsConversationsOnReady = [
        () => {
          if (!window.HubSpotConversations.widget.status().loaded)
            window.HubSpotConversations.widget.load()
        },
      ]
    }

    return () => {
      if (
        window.HubSpotConversations &&
        window.HubSpotConversations.widget.status().loaded
      )
        window.HubSpotConversations.widget.remove()
    }
  }, [isMobile])

  return (
    <>
      <Container style={{ minHeight: windowHeight }} isOpen={open}>
        <NavContainer visible={open}>
          <NavLink to="/" onClick={() => setOpen(false)}>
            Home
          </NavLink>
          <NavLink to="/newsroom/" hrefLang="en" onClick={() => setOpen(false)}>
            Media relations
          </NavLink>
          <NavLink to="/investors/" hrefLang="en" onClick={() => setOpen(false)}>
            Investor relations
          </NavLink>
          <NavLink to="/careers/" hrefLang="en" onClick={() => setOpen(false)}>
            Careers
          </NavLink>
        </NavContainer>

        {!hideMenu() && (
          <>
            <OpenButton
              data-cy="mobile-menu-open-button"
              visible={!open}
              onClick={() => {
                setOpen(true)
              }}
            >
              <span />
              <span />
              <span />
            </OpenButton>

            <CloseButton visible={open} onClick={() => setOpen(false)}>
              <span />
              <span />
            </CloseButton>
          </>
        )}
      </Container>
    </>
  )
}

FullScreenNav.defaultProps = {
  hideOnFullScroll: true,
}

FullScreenNav.propTypes = {
  hideOnFullScroll: PropTypes.bool.isRequired,
}

export default FullScreenNav
