import React from "react"
import styled, { css } from "styled-components"
import { rgba } from "polished"

import ArrowBack from "../assets/images/arrow-back.inline.svg"
import ArrowNext from "../assets/images/arrow-next.inline.svg"
import ArrowUp from "../assets/images/arrow-up.inline.svg"
import NewTab from "../assets/images/new-tab.inline.svg"
import Preview from "../assets/images/preview.inline.svg"
import Download from "../assets/images/download.inline.svg"
import Copy from "../assets/images/publishing/copy.inline.svg"
import Check from "../assets/images/check.inline.svg"

import Spinner from "../components/spinner"

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  height: 60px;
  max-width: 300px;
  width: calc(100% - 40px);
  background: ${props => props.backgroundColor};
  border: 1px solid #000;
  box-shadow: 5px 5px 0 0 ${rgba("#000", 0.1)};
  font-size: 20px;
  font-weight: 700;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  transition: background 0.5s ease, color 0.5s ease;

  &:hover {
    box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 1);
  }

  &:active {
    color: ${rgba("#000", 0.1)};
    transform: translate3d(5px, 5px, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  }

  ${props =>
    props.disabled &&
    css`
      background: #f1efec;
      pointer-events: none;
    `}
`

const BlankText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(100% + 10px);
  font-size: 12px;
  font-weight: 400;
  color: ${rgba("#000", 0.7)};
  pointer-events: none;

  span {
    margin-right: 10px;
  }
`

const ContinueButton = ({
  inNewTab = false,
  children,
  icon = "",
  onClick = null,
  className,
  backgroundColor = "#cef9e4",
  disabled = false,
}) => (
  <Button
    onClick={onClick}
    className={className}
    backgroundColor={backgroundColor}
    disabled={disabled}
  >
    {icon === "back" && <ArrowBack />}
    {children}
    {icon === "next" && <ArrowNext />}
    {icon === "up" && <ArrowUp />}
    {icon === "copy" && <Copy />}
    {icon === "preview" && <Preview />}
    {icon === "download" && <Download />}
    {icon === "loading" && <Spinner />}
    {icon === "success" && <Check />}
    {inNewTab && (
      <BlankText>
        <span>The link will open a new tab</span> <NewTab />
      </BlankText>
    )}
  </Button>
)

export default ContinueButton
