import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex: 1;
  /* padding-left: 300px; */
  background: #fff;
`

// const SideBar = styled.div`
//   position: fixed;
//   left: 0;
//   top: 0;
//   bottom: 0;
//   width: 300px;
//   padding: 60px 40px 40px;
//   background-color: #f1efec;
//   display: flex;
//   flex-direction: column;
//   box-sizing: border-box;
// `

// const SiteTag = styled.div`
//   display: flex;
//   margin-bottom: 50px;
// `

// const SiteDetails = styled.div`
//   height: 38px;
//   display: flex;
//   height: 100%;
//   justify-content: space-between;
//   flex-direction: column;
// `

// const SiteLogo = styled.img`
//   display: flex;
//   height: 40px;
//   width: 40px;
//   margin-right: 12px;
// `

// const Text = styled.p`
//   margin: 0;
//   color: #000000;
//   font-weight: normal;
//   font-size: 16px;
// `

// const URL = styled.p`
//   margin: 0;
//   opacity: 0.5;
//   color: #000000;
//   font-size: 14px;
//   margin-top: 10px;
// `

// const Status = styled.div`
//   box-sizing: border-box;
//   height: 49px;
//   width: 220px;
//   border: 1px solid rgba(0, 0, 0, 0.1);
//   padding: 10px 20px;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   margin-bottom: 100px;

//   h6 {
//     color: #000000;
//     font-size: 10px;
//     letter-spacing: 2px;
//     margin: 0;
//     text-transform: uppercase;
//     font-weight: normal;
//   }

//   ${Text} {
//     position: relative;
//     display: inline-block;
//     align-self: flex-start;

//     &:after {
//       content: "";
//       height: 9px;
//       width: 9px;
//       background-color: #00ffaa;
//       position: absolute;
//       border-radius: 50%;
//       top: 0;
//       bottom: 0;
//       margin: auto;
//       left: calc(100% + 10px);
//     }
//   }
// `

// const Menu = styled.ul`
//   display: flex;
//   flex-direction: column;
//   margin: 0;
//   padding: 0;
// `

// const Item = styled.li`
//   margin: 0;
//   padding: 0;
//   color: #000000;
//   list-style: none;
//   font-size: 16px;
//   line-height: 20px;
//   margin-bottom: 20px;
//   cursor: pointer;
// `

// const Logout = styled(Text)`
//   margin-top: auto;
//   cursor: pointer;
//   position: relative;

//   &:after {
//     content: "";
//     box-sizing: border-box;
//     height: 1px;
//     left: 0;
//     right: 0;
//     position: absolute;
//     bottom: calc(100% + 24px);
//     border: 1px solid #787776;
//   }
// `

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const DashboardLayout = ({ children, location }) => (
  <Container>
    {/* <SideBar>
      <SiteTag>
        <SiteLogo
          src="https://media.licdn.com/dms/image/C4E0BAQGGhjx1zQGvdA/company-logo_200_200/0?e=2159024400&v=beta&t=qTjfYMt0MCgEUI2W6XaykAfhWrAKEc2dVAZ-HaAk7Ss"
          alt=""
        />
        <SiteDetails>
          <Text>Regily</Text>
          <URL>www.regily.com</URL>
        </SiteDetails>
      </SiteTag>
      <Status>
        <h6>status</h6>
        <Text>Live</Text>
      </Status>
      <Menu>
        <Item>Start</Item>
        <Item>Data to collect</Item>
        <Item>Settings</Item>
      </Menu>
      <Logout>Logout</Logout>
    </SideBar> */}
    <Content>{children}</Content>
  </Container>
)

export default DashboardLayout
