import React, { useContext, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import axios from "axios"

import GlobalContext from "../context/global-context"
import closeIcon from "../assets/images/close-icon.svg"

import RegilyBlackLogo from "../assets/images/regily-black-logo.svg"

const Logo = styled(Link)`
  width: 116px;
  height: 33px;
  text-indent: -9999px;
  background: url(${RegilyBlackLogo}) no-repeat center/contain;
`

const Wrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 1000;
  background: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.5s ease;
  flex-direction: column;

  @media screen and (max-width: 980px) {
    position: static;
  }

  ${props =>
    props.hide &&
    css`
      @media screen and (max-width: 640px) {
        display: none;
      }
    `}
`

const Container = styled.div`
  height: 30px;
  max-width: 1380px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: relative;

  @media screen and (max-width: 1380px) {
    left: 0;
    width: 100%;
    right: 0;
  }

  @media screen and (max-width: 980px) {
    justify-content: center;

    &:after {
      margin: auto;
    }
  }
`

const NavContainer = styled.div`
  display: flex;
  margin-left: auto;
  @media screen and (max-width: 980px) {
    display: none;
  }
`

const NavLink = styled(Link)`
  padding: 0 15px;
  display: flex;
  position: relative;
  height: 40px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px dashed transparent;
  font-size: 13px;
  transition: 0.4s border;
  margin-left: 5px;
  font-weight: 700;

  span {
    height: 13px;
  }

  &.active,
  &:hover {
    border: 1px dashed #000;
  }

  &.unhoverable {
    border: none;
  }

  &.hide {
    display: none;
  }
`

const Banner = styled.div`
  width: calc(100% + 40px);
  height: 70px;
  background: #fbd63e;
  display: flex;
  color: #000;
  align-items: center;
  padding: 0 80px 0 20px;
  box-sizing: border-box;
  position: relative;
  justify-content: center;
  p {
    br {
      display: none;
    }
    a {
      color: #000;
      text-decoration: underline;
      font-weight: 700;
    }
  }
  @media screen and (max-width: 980px) {
    p {
      line-height: 28px;
      br {
        display: initial;
      }
    }
  }

  @media screen and (max-width: 640px) {
    p {
      font-size: 13px;
      line-height: 20px;
    }
  }
`

const CloseButton = styled.button`
  height: 40px;
  width: 40px;
  border-radius: 2px;
  box-shadow: none;
  border: none;
  position: absolute;
  right: 20px;
  background: url(${closeIcon}) no-repeat center, rgba(255, 255, 255, 0.4);
  cursor: pointer;
`

const NavBar = ({ color }) => {
  const globalContext = useContext(GlobalContext)
  const [corporateContent, setCorporateContent] = useState(`
  <span>
  <b>We are hiring</b>, if you are best at what you do and want to be part of our journey
</span>
<a
  target="blank"
  href="https://group.checkin.com/careers/"
>
  Reach out! 
</a>
    `)

  useEffect(() => {
    axios.get('https://strapi.regily.io/banner')
      .then(response => {
        if (response.data.activate)
          setCorporateContent(response.data.corporate_content)
      })
  }, [])

  return (
    <Wrapper color={color}>
      {globalContext.showIPOBanner && (
        <Banner>
          <p dangerouslySetInnerHTML={{ __html: corporateContent }} />
          <CloseButton onClick={() => globalContext.toggleIPOBanner(true)} />
        </Banner>
      )}
      <Container>
        <Logo to="/" />

        <NavContainer>
          <>
            <NavLink
              to={`/newsroom/`}
              activeClassName="active"
              partiallyActive={true}
              hrefLang="en"
            >
              Media relations
            </NavLink>

            <NavLink
              to={`/investors/`}
              activeClassName="active"
              partiallyActive={true}
              hrefLang="en"
            >
              Investor relations
            </NavLink>

            <NavLink
              to={`/careers/`}
              activeClassName="active"
              partiallyActive={true}
              hrefLang="en"
            >
              Careers
            </NavLink>
          </>
        </NavContainer>
      </Container>
    </Wrapper>
  )
}

NavBar.defaultProps = {
  minimized: true,
  color: "#f9f9f8",
}

NavBar.prototypes = {
  minimized: PropTypes.bool,
  color: PropTypes.string,
}

export default NavBar