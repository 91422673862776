import { combineReducers } from "redux"
import _get from "lodash.get"
import { isMobile, isMobileOnly, isTablet } from "react-device-detect"

import {
  DETECT_DEVICE,
  SET_WEBSITE,
  SET_REFRESH_DURING_SCANNING,
  SET_HEADERS,
  SET_SCANNING_STATUS,
  SET_SCANNING_TOKEN,
  SET_SCANNING_RESULTS,
  SET_SCANNING_PROGRESS,
  SET_SCANNING_ILLUSTRATION,
  RESET_SCANNING_STATE,
  SET_BETA_EMAIL,
  SET_BETA_USERS_LIMIT,
  SET_BETA_FIRSTNAME,
  SET_BETA_SHEET_RANGE,
  RESET_BETA,
  SET_PUBLISHING_DATA,
  SET_CUSTOMISATION_KEYS,
  RESET_GLOBAL_STATE,
  SET_WINDOW_HEIGHT,
  SET_SITE_ID,
  SET_FORCE_WP_FLOW,
  SET_REMOTE_TOOL_SITE,
  SET_REMOTE_TOOL_RESULTS,
  SET_REMOTE_TOOL_ERROR,
  SET_REMOTE_TOOL_FETCHING,
  SET_REMOTE_TOOL_HYDRATING,
  SET_REMOTE_TOOL_AFFILIATES,
  SET_REMOTE_TOOL_NEW_AFFILIATE,
  RESET_REMOTE_TOOL,
  UPDATE_REMOTE_TOOL,
  UPDATE_CALENDAR,
  RESET_CALENDAR,
  UPDATE_COMPANY_TRACKING,
  RESET_COMPANY_TRACKING,
} from "./actions"

const searchParamsContains = param => {
  var url = new URL(window.document.location.href)
  var entries = Array.from(url.searchParams.keys())
  return (
    entries.filter(s => s.toLowerCase().indexOf(param.toLowerCase()) >= 0)
      .length > 0
  )
}

const getSearchParam = param => {
  var url = new URL(window.document.location.href)
  return url.searchParams.get(param)
}

const searchParams = () => {
  return {
    utm: searchParamsContains("utm"),
    gclid: getSearchParam("gclid"),
    fbclid: searchParamsContains("fbclid"),
    utmTerm: getSearchParam("utm_term"),
    referrer: window.document.referrer,
  }
}

const initialGlobalState = {
  windowHeight: 0,
  website: "",
  siteId: null,
  refreshDuringScanning: false,
  isMobile: null,
  isMobileOnly: null,
  isTablet: null,
  marketingFlow:
    typeof window !== "undefined" &&
    [
      "www.flowbrain.io",
      "develop-flowbrain.regily.com",
      "develop.flowbrain.io",
      "flowbrain.io",
      "regily.io",
      "regily.com",
      "staging-flowbrain.regily.com",
      "staging.flowbrain.io",
      "localhost",
    ].includes(window.location.hostname),
  forceWPFlow: false,
  searchParams: typeof window !== "undefined" && searchParams(),
}

const global = (state = initialGlobalState, action) => {
  switch (action.type) {
    case DETECT_DEVICE:
      return {
        ...state,
        isMobile,
        isMobileOnly,
        isTablet,
      }

    case SET_WEBSITE:
      const cleanWebsite = action.value
        .replace(/(^\w+:|^)\/\//, "")
        .replace(/\/$/g, "")

      const httpsWebsite = `https://${cleanWebsite}`
      const hostname = cleanWebsite.split("/")[0]
      const parts = cleanWebsite.split(".")
      const partner = parts[0] === "www" ? parts[1] : parts[0]

      return {
        ...state,
        website: action.value,
        httpsWebsite,
        cleanWebsite,
        partner,
        hostname,
      }

    case SET_REFRESH_DURING_SCANNING:
      return {
        ...state,
        refreshDuringScanning: action.value,
      }

    case SET_WINDOW_HEIGHT:
      return {
        ...state,
        windowHeight: action.height,
      }

    case RESET_GLOBAL_STATE:
      return {
        ...initialGlobalState,
        isMobile,
        isMobileOnly,
        isTablet,
      }

    case SET_SITE_ID:
      return {
        ...state,
        siteId: action.id,
      }

    case SET_FORCE_WP_FLOW:
      return {
        ...state,
        forceWPFlow: true,
      }

    default:
      return state
  }
}

const initialScanningState = {
  token: "",
  status: null,
  results: {
    FormsParser: [],
  },
  progress: "",
  illustration: "",
  headers: {
    large: "",
    small: "",
  },
}

const scanning = (state = initialScanningState, action) => {
  switch (action.type) {
    case SET_HEADERS:
      return {
        ...state,
        headers: {
          ...state.headers,
          ...action.value,
        },
      }

    case SET_SCANNING_ILLUSTRATION:
      return { ...state, illustration: action.illustration }

    case SET_SCANNING_PROGRESS:
      return { ...state, progress: action.progress }

    case SET_SCANNING_STATUS:
      return { ...state, status: action.status }

    case SET_SCANNING_TOKEN:
      return { ...state, token: action.token }

    case SET_SCANNING_RESULTS:
      const theme = _get(action.results, "Theme.theme")
      if (theme && theme === '{"error":true}') action.results.Theme = null
      return { ...state, results: { ...state.results, ...action.results } }

    case RESET_SCANNING_STATE:
      return initialScanningState

    default:
      return state
  }
}

const initialBetaState = {
  email: "",
  firstname: "",
  usersAboveLimit: null,
  sheetRange: null,
}

const beta = (state = initialBetaState, action) => {
  switch (action.type) {
    case SET_BETA_EMAIL:
      return { ...state, email: action.email }

    case SET_BETA_USERS_LIMIT:
      return { ...state, usersAboveLimit: action.above }

    case SET_BETA_FIRSTNAME:
      return { ...state, firstname: action.firstname }

    case SET_BETA_SHEET_RANGE:
      return { ...state, sheetRange: action.range }

    case RESET_BETA:
      return { ...initialBetaState }
    default:
      return state
  }
}

const initialPublishingState = {
  data: {},
}

const publishing = (state = initialPublishingState, action) => {
  switch (action.type) {
    case SET_CUSTOMISATION_KEYS: {
      return { ...state, customisationKeys: action.keys }
    }

    case SET_PUBLISHING_DATA:
      return { ...state, data: action.data }

    default:
      return state
  }
}

const initialRemoteToolState = {
  site: "",
  cleanSite: "",
  results: null,
  error: false,
  fetching: false,
  hydrating: false,
  affiliates: [],
  affiliatesToDelete: [],
  newAffiliate: {
    domain: "",
    trackingURL: "",
  },
  shareID: null,
  editMode: false,
  selectedAffiliat: null,
}

const remoteTool = (state = initialRemoteToolState, action) => {
  switch (action.type) {
    case SET_REMOTE_TOOL_SITE:
      return {
        ...state,
        site: action.site,
        cleanSite: action.site.replace(/(^\w+:|^)\/\//, "").replace(/\/$/g, ""),
      }

    case SET_REMOTE_TOOL_RESULTS:
      return { ...state, results: action.results }

    case SET_REMOTE_TOOL_FETCHING:
      return { ...state, fetching: action.fetching }

    case SET_REMOTE_TOOL_HYDRATING:
      return { ...state, hydrating: action.hydrating }

    case SET_REMOTE_TOOL_ERROR:
      return { ...state, error: action.error }

    case SET_REMOTE_TOOL_AFFILIATES:
      return {
        ...state,
        affiliates: action.affiliates
          .map(aff => ({
            id: aff.id || null,
            domain: aff.domain.replace(/(^\w+:|^)\/\//, "").replace(/\/$/g, ""),
            trackingURL: aff.trackingURL
              .replace(/(^\w+:|^)\/\//, "")
              .replace(/\/$/g, ""),
            targetURL: aff.targetURL,
          }))
          .filter(aff => !!aff.id),
      }

    case SET_REMOTE_TOOL_NEW_AFFILIATE:
      return {
        ...state,
        newAffiliate: action.affiliate,
      }

    case UPDATE_REMOTE_TOOL:
      return {
        ...state,
        ...action.data,
      }

    case RESET_REMOTE_TOOL:
      return { ...initialRemoteToolState }

    default:
      return state
  }
}

const initialCalendarState = {
  currentPage: "slotSelector",
  fetchingDays: false,
  selectedRange: {
    start: null,
    end: null,
  },
  detectedTimezone: null,
  selectedTimeZone: null,
  selectedDay: null,
  selectedSlot: null,
  days: null,
  currentWeek: null,
  primaryEmail: "",
  businessDescription: "",
  guestsEmails: [],
  eventId: null,
}

const calendar = (state = initialCalendarState, action) => {
  switch (action.type) {
    case UPDATE_CALENDAR:
      return {
        ...state,
        ...action.data,
      }

    case RESET_CALENDAR:
      return { ...initialCalendarState }

    default:
      return state
  }
}

const initialCompanyTrackingState = {
  company: {
    name: "",
    industry: "",
    tags: [],
    employees: null,
    tech: [],
    description: "",
    foundedYear: null,
    estimatedAnnualRevenue: "",
    logo: "",
    country: "",
    city: "",
  },
  behavior: {
    returningVisitor: null,
    numberOfVisits: null,
    scannedWebsite: null,
    scannedWebsites: [],
    enteredEmail: null,
  },
}

const companyTracking = (state = initialCompanyTrackingState, action) => {
  switch (action.type) {
    case UPDATE_COMPANY_TRACKING:
      return {
        ...state,
        ...action.data,
      }

    case RESET_COMPANY_TRACKING:
      return { ...initialCompanyTrackingState }

    default:
      return state
  }
}

export default combineReducers({
  global,
  scanning,
  beta,
  publishing,
  remoteTool,
  calendar,
  companyTracking,
})
